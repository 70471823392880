<template>
  <div class="assignment-dashboard my-10 px-2">
    <div
      class="d-flex justify-space-between mb-4"
      :style="{ position: 'relative' }"
    >
      <!-- left -->
      <div class="d-flex">
        <!-- new assignment -->
        <div v-if="isAdminMode && !requestedView" class="text-center">
          <span class="overline"> New </span>
          <div>
            <v-btn
              min-width="40px"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              :loading="dropdownsLoading"
              @click="setNewAssignmentModalOpen(true)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- add filters (admin) -->
        <div v-if="isAdminMode" class="ml-2 text-center">
          <span class="overline"> Add </span>
          <div>
            <v-badge
              bordered
              color="accent"
              overlap
              :value="filtersBadge"
              :content="filtersBadge"
            >
              <v-btn
                min-width="40px"
                height="38px"
                color="#494949"
                class="px-0"
                @click="filtersDialog = true"
              >
                <v-icon color="white">mdi-filter-plus</v-icon>
              </v-btn></v-badge
            >
          </div>
        </div>

        <v-dialog
          content-class="filters"
          transition="dialog-top-transition"
          max-width="1500px"
          v-model="filtersDialog"
          hide-overlay
          persistent
          no-click-animation
        >
          <v-card
            class="d-flex justify-space-around flex-wrap px-6 pt-md-2 pt-lg-4 pb-md-4 pb-lg-6"
          >
            <!-- pay period filter -->
            <div>
              <span class="overline"> Pay Period </span>
              <div class="d-flex">
                <v-autocomplete
                  class="assignment-dashboard__month-selector popup selector mr-1"
                  :items="monthOptions"
                  v-model="ppm"
                  solo
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  @change="handleFilterQuery('ppm', $event)"
                ></v-autocomplete>
                <v-autocomplete
                  class="assignment-dashboard__year-selector popup selector"
                  :items="yearOptions"
                  v-model="ppy"
                  solo
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  @change="handleFilterQuery('ppy', $event)"
                >
                </v-autocomplete>
              </div>
            </div>

            <!-- request date filter -->
            <div class="ml-2 text-center">
              <span class="overline"> Request Date </span>
              <div class="d-flex align-end">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  solo-inverted
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedRequestDate"
                      class="assignment-dashboard__date-selector popup selector"
                      solo
                      dense
                      readonly
                      hide-details
                      clearable
                      :disabled="showTableLoader || isTableUpdating"
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="clearFilter('RequestDate')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterRequestDate"
                    @input="handleFilterQuery('RequestDate', $event)"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>

            <!-- delivery date filter -->
            <div class="ml-2 text-center">
              <span class="overline">
                {{ isAdminMode ? "Delivery Date" : "Due Date" }}
              </span>
              <div class="d-flex align-end">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  solo-inverted
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedDeliveryDate"
                      class="assignment-dashboard__date-selector popup selector"
                      solo
                      dense
                      readonly
                      hide-details
                      clearable
                      :disabled="showTableLoader || isTableUpdating"
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="clearFilter('DeliveryDate')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterDeliveryDate"
                    @input="handleFilterQuery('DeliveryDate', $event)"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>

            <!-- project filter -->
            <div v-if="isAdminMode && !requestedView" class="ml-2 text-center">
              <span class="overline"> Project </span>
              <div>
                <v-autocomplete
                  class="assignment-dashboard__project-selector popup selector"
                  :items="projectsList"
                  item-text="name"
                  return-object
                  v-model="filterProject"
                  solo
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="dropdownsLoading"
                  @input="handleFilterQuery('Project', $event)"
                ></v-autocomplete>
              </div>
            </div>

            <!-- Shakespeare filter -->
            <div v-if="!requestedView" class="ml-2 d-flex align-end">
              <div class="d-flex">
                <v-btn-toggle
                  class="elevation-2"
                  color="accent"
                  dense
                  v-model="filterShakespeare"
                  @change="handleFilterQuery('Shakespeare', $event)"
                >
                  <v-btn
                    min-width="42px"
                    height="38px"
                    class="px-0"
                    :disabled="showTableLoader || isTableUpdating"
                  >
                    <v-img
                      :src="SHK"
                      width="40px"
                      height="36px"
                      alt="Shakespeare"
                    />
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>

            <!-- GEO filter -->
            <div v-if="isAdminMode" class="ml-2 text-center">
              <span class="overline"> GEO </span>
              <div>
                <v-autocomplete
                  class="assignment-dashboard__geo-selector popup selector"
                  :items="geoOptions"
                  v-model="filterGeo"
                  solo
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="dropdownsLoading"
                  @input="handleFilterQuery('Geo', $event)"
                ></v-autocomplete>
              </div>
            </div>

            <!-- person filter -->
            <div
              v-if="isAdminMode ? earningsMode !== 0 : true"
              class="ml-2 text-center"
            >
              <span class="overline">
                {{ "Role".padStart(isAdminMode ? 13 : 4, "Person / ") }}
              </span>
              <div class="d-flex">
                <v-autocomplete
                  v-if="isAdminMode"
                  class="assignment-dashboard__person-selector popup selector mr-2"
                  :items="staffList"
                  :item-text="fullName"
                  return-object
                  v-model="filterPerson"
                  solo
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="staffLoading || dropdownsLoading"
                  @input="handleFilterQuery('Person', $event)"
                ></v-autocomplete>
                <v-autocomplete
                  class="assignment-dashboard__role-selector popup selector"
                  :items="roles"
                  v-model="filterRole"
                  solo
                  dense
                  hide-details
                  :disabled="
                    (isAdminMode ? !filterPerson : false) ||
                    showTableLoader ||
                    isTableUpdating
                  "
                  @input="handleFilterQuery('Role', $event)"
                >
                </v-autocomplete>
              </div>
            </div>

            <!-- media type filter -->
            <div class="ml-2 text-center">
              <span class="overline"> Media Type </span>
              <div>
                <v-autocomplete
                  class="assignment-dashboard__media-selector popup selector"
                  :items="mediaTypeOptions"
                  v-model="filterMediaType"
                  solo
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="mediaTypesLoading"
                  @input="handleFilterQuery('MediaType', $event)"
                ></v-autocomplete>
              </div>
            </div>

            <!-- request type filter -->
            <div class="ml-2 text-center">
              <span class="overline"> Request Type </span>
              <div>
                <v-autocomplete
                  class="assignment-dashboard__request-selector popup selector"
                  :items="requestTypeOptions"
                  v-model="filterRequestType"
                  solo
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="requestTypesLoading"
                  @input="handleFilterQuery('RequestType', $event)"
                ></v-autocomplete>
              </div>
            </div>

            <!-- status filter -->
            <div v-if="!isAdminMode" class="ml-2 text-center">
              <span class="overline"> Status </span>
              <div class="d-flex">
                <v-autocomplete
                  class="assignment-dashboard__status-selector popup selector"
                  :items="statusOptions"
                  v-model="filterStatus"
                  item-text="value"
                  solo
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="statusOptionsLoading"
                  @input="handleFilterQuery('Status', $event)"
                >
                </v-autocomplete>
              </div>
            </div>

            <!-- search -->
            <div class="ml-4 text-center">
              <span class="overline"> Search </span>
              <div>
                <v-text-field
                  v-model="filterSearch"
                  class="assignment-dashboard__search-selector popup selector"
                  prepend-inner-icon="mdi-magnify"
                  solo
                  dense
                  hide-details
                  clearable
                  :disabled="showTableLoader || isTableUpdating"
                  @click:prepend-inner="applySearch(false)"
                  @click:clear="applySearch(true)"
                  @keydown.enter="applySearch(false)"
                />
              </div>
            </div>

            <v-card-actions class="ml-auto pa-0 pt-2 align-end">
              <v-btn
                color="secondary"
                @click="clearFilter(false)"
                :disabled="!filtersBadge || showTableLoader || isTableUpdating"
                >Clear Filters</v-btn
              >
              <v-btn color="#494949" dark @click="filtersDialog = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- clear filters (admin) -->
        <div v-if="isAdminMode" class="text-center ml-2">
          <span class="overline"> Clear </span>
          <div>
            <v-btn
              min-width="40px"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              :disabled="showTableLoader || isTableUpdating"
              @click="clearFilter(false)"
            >
              <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
          </div>
        </div>

        <v-dialog
          max-width="1200"
          :value="newAssignmentModalOpen"
          :persistent="true"
          @click:outside="closeModal"
          @keydown.esc="closeModal"
        >
          <ADNewAssignmentModal
            ref="newAssignmentModal"
            @assignment-dashboard-table-refresh="getTableData(true)"
          />
        </v-dialog>

        <v-dialog
          v-model="hoursSubmissionModalOpen"
          max-width="795"
          persistent
        >
          <ADHoursSubmission />
        </v-dialog>

        <v-dialog v-model="closeModalConfirmation" persistent max-width="200">
          <v-card>
            <v-card-title class="text-h5 justify-center">
              Close modal?
            </v-card-title>
            <v-card-text class="text-center"
              >All changes will be premanently deleted.</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="closeModalConfirmation = false"
              >
                No
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="closeAssignmentModal"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="updateBatchConfirmationModal" persistent max-width="200">
          <v-card>
            <v-card-title class="text-h5 justify-center">
              Are you sure?
            </v-card-title>
            <v-card-text class="text-center"
              >{{ countFieldsToUpdate }} fields in {{ bulkQueryEdit ? tabledataAmount : selectedDashboardRows.length }} selected records will be overriden.</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                :loading="bulkQueryEditLoading"
                @click="setUpdateBatchConfirmation(false)"
              >
                No
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                :loading="bulkQueryEditLoading"
                @click="updateBatchAssignments"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="closePOConfirmation" persistent max-width="200">
          <v-card>
            <v-card-text class="text-center"
              >PO Number: {{notActivePONumber}} is inactive. Would you like to continue?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="removePOFromForm"
              >
                No
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="closePOConfirmation = false"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- pay period filter -->
        <div
          v-if="!selectedDashboardRows.length"
          :class="{ 'ml-2': isAdminMode }"
        >
          <span class="overline"> Pay Period </span>
          <div class="d-flex">
            <v-autocomplete
              class="assignment-dashboard__month-selector selector mr-1"
              :class="{ laptop }"
              :items="monthOptions"
              v-model="ppm"
              solo
              dense
              hide-details
              :disabled="showTableLoader || isTableUpdating"
              @change="handleFilterQuery('ppm', $event)"
            ></v-autocomplete>
            <v-autocomplete
              class="assignment-dashboard__year-selector selector"
              :class="{ laptop }"
              :items="yearOptions"
              v-model="ppy"
              solo
              dense
              hide-details
              :disabled="showTableLoader || isTableUpdating"
              @change="handleFilterQuery('ppy', $event)"
            >
            </v-autocomplete>
          </div>
        </div>

        <!-- request date filter -->
        <div
          v-if="!selectedDashboardRows.length"
          class="ml-lg-2 ml-md-1 text-center"
        >
          <span class="overline"> Request Date </span>
          <div class="d-flex align-end">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              solo-inverted
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedRequestDate"
                  :class="{ laptop }"
                  class="assignment-dashboard__date-selector selector"
                  solo
                  dense
                  readonly
                  hide-details
                  clearable
                  :disabled="showTableLoader || isTableUpdating"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearFilter('RequestDate')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterRequestDate"
                @input="handleFilterQuery('RequestDate', $event)"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>

        <!-- delivery date filter -->
        <div
          v-if="!selectedDashboardRows.length"
          class="ml-lg-2 ml-md-1 text-center"
        >
          <span class="overline">
            {{ isAdminMode ? "Delivery Date" : "Due Date" }}
          </span>
          <div class="d-flex align-end">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              solo-inverted
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedDeliveryDate"
                  :class="{ laptop }"
                  class="assignment-dashboard__date-selector selector"
                  solo
                  dense
                  readonly
                  hide-details
                  clearable
                  :disabled="showTableLoader || isTableUpdating"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearFilter('DeliveryDate')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterDeliveryDate"
                @input="handleFilterQuery('DeliveryDate', $event)"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>

        <!-- project filter -->
        <div
          v-if="!selectedDashboardRows.length && isAdminMode && !requestedView"
          class="ml-lg-2 ml-md-1 text-center"
        >
          <span class="overline"> Project </span>
          <div>
            <v-autocomplete
              class="assignment-dashboard__project-selector selector"
              :class="{ laptop, laptopSmaller }"
              :items="projectsList"
              item-text="name"
              return-object
              v-model="filterProject"
              solo
              dense
              hide-details
              :disabled="showTableLoader || isTableUpdating"
              :loading="dropdownsLoading"
              @input="handleFilterQuery('Project', $event)"
            ></v-autocomplete>
          </div>
        </div>

        <!-- Shakespeare filter -->
        <div
          v-if="!selectedDashboardRows.length && !requestedView"
          class="ml-lg-2 ml-md-1 d-flex align-end"
        >
          <div class="d-flex">
            <v-btn-toggle
              class="elevation-2"
              color="accent"
              dense
              v-model="filterShakespeare"
              @change="handleFilterQuery('Shakespeare', $event)"
            >
              <v-btn
                min-width="42px"
                height="38px"
                class="px-0"
                :disabled="showTableLoader || isTableUpdating"
              >
                <v-img
                  :src="SHK"
                  width="40px"
                  height="36px"
                  alt="Shakespeare"
                />
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>

        <!-- GEO filter -->
        <div
          v-if="!selectedDashboardRows.length && isAdminMode"
          v-show="(!expandMenu && laptopSmaller) || !laptopSmaller"
          class="ml-lg-2 ml-md-1 text-center"
        >
          <span class="overline"> GEO </span>
          <div>
            <v-autocomplete
              class="assignment-dashboard__geo-selector selector"
              :class="{ laptop }"
              :items="geoOptions"
              v-model="filterGeo"
              solo
              dense
              hide-details
              :disabled="showTableLoader || isTableUpdating"
              :loading="dropdownsLoading"
              @input="handleFilterQuery('Geo', $event)"
            ></v-autocomplete>
          </div>
        </div>

        <!-- person filter -->
        <div
          v-if="
            !selectedDashboardRows.length &&
            (isAdminMode ? earningsMode !== 0 : true)
          "
          v-show="
            (laptop && !expandMenu) ||
            (isAdminMode ? !expandMenu : true && !laptop)
          "
          class="ml-lg-2 ml-md-1 text-center"
        >
          <span class="overline">
            {{ "Role".padStart(isAdminMode ? 13 : 4, "Person / ") }}
          </span>
          <div class="d-flex">
            <v-autocomplete
              v-if="isAdminMode"
              :class="{ laptop, laptopSmaller }"
              class="assignment-dashboard__person-selector selector mr-lg-2 mr-md-1"
              :items="staffList"
              :item-text="fullName"
              return-object
              v-model="filterPerson"
              solo
              dense
              hide-details
              :disabled="showTableLoader || isTableUpdating"
              :loading="staffLoading || dropdownsLoading"
              @input="handleFilterQuery('Person', $event)"
            ></v-autocomplete>
            <v-autocomplete
              class="assignment-dashboard__role-selector selector"
              :items="roles"
              v-model="filterRole"
              solo
              dense
              hide-details
              :disabled="
                (isAdminMode ? !filterPerson : false) ||
                showTableLoader ||
                isTableUpdating
              "
              @input="handleFilterQuery('Role', $event)"
            >
            </v-autocomplete>
          </div>
        </div>

        <!-- status filter -->
        <div
          v-if="!isAdminMode && !selectedDashboardRows.length"
          v-show="(laptop && !expandMenu) || !laptop"
          class="ml-lg-2 ml-md-1 text-center"
        >
          <span class="overline"> Status </span>
          <div class="d-flex">
            <v-autocomplete
              class="assignment-dashboard__status-selector selector"
              :items="statusOptions"
              v-model="filterStatus"
              item-text="value"
              solo
              dense
              hide-details
              :disabled="showTableLoader || isTableUpdating"
              :loading="statusOptionsLoading"
              @input="handleFilterQuery('Status', $event)"
            >
            </v-autocomplete>
          </div>
        </div>

        <!-- add filters (freelancer) -->
        <div
          v-if="!isAdminMode"
          class="text-center"
          :class="{ 'ml-2': !selectedDashboardRows.length }"
        >
          <span class="overline"> Add </span>
          <div>
            <v-badge
              bordered
              color="accent"
              overlap
              :value="filtersBadge"
              :content="filtersBadge"
            >
              <v-btn
                min-width="40px"
                height="38px"
                color="#494949"
                class="px-0"
                @click="filtersDialog = true"
              >
                <v-icon color="white">mdi-filter-plus</v-icon>
              </v-btn></v-badge
            >
          </div>
        </div>

        <!-- clear filters (freelancer) -->
        <div v-if="!isAdminMode" class="ml-2 text-center">
          <span class="overline"> Clear </span>
          <div>
            <v-btn
              min-width="40px"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              :disabled="showTableLoader || isTableUpdating"
              @click="clearFilter(false)"
            >
              <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- user's earnings -->
        <div
          v-if="!selectedDashboardRows.length && !requestedView"
          v-show="!expandMenu"
          class="text-center"
          :class="[
            {
              'd-flex flex-column justify-end': earningsMode !== 0,
            },
            isAdminMode ? 'ml-lg-2 ml-md-1' : 'ml-2',
          ]"
        >
          <span v-if="isAdminMode ? earningsMode === 0 : true" class="overline">
            {{
              `${
                isAdminMode
                  ? "My Invoice"
                  : `${filtersBadge ? "Filtered" : ""} Invoice Amount`
              }`
            }}
          </span>
          <div class="d-flex">
            <v-btn-toggle
              class="elevation-2"
              color="accent"
              dense
              v-model="earningsMode"
            >
              <v-btn
                min-width="fit-content"
                :width="
                  earningsMode === 0 || isAdminMode ? 'fit-content' : '200px'
                "
                height="38px"
                :loading="showTableLoader || isTableUpdating"
                ><v-icon color="#000000de">mdi-currency-usd</v-icon
                ><span>{{
                  earningsMode === 0
                    ? `Total: ${totalEarnings}`
                    : isAdminMode
                    ? ""
                    : "Invoice"
                }}</span></v-btn
              >
            </v-btn-toggle>
            <v-btn
              v-if="earningsMode === 0"
              height="38px"
              :min-width="laptop ? '40px' : '42px'"
              class="elevation-2 px-0 ml-2"
              color="secondary"
              :disabled="!tableData.length || isTableUpdating"
              @click="downloadInvoice"
            >
              <v-icon>mdi-tray-arrow-down</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- bulk operations -->
        <div
          v-if="selectedDashboardRows.length"
          class="d-flex text-center ml-2"
        >
          <div>
            <span class="overline"> Bulk Status Assign </span>
            <div class="d-flex align-end">
              <v-autocomplete
                v-model="bulkStatusValue"
                class="assignment-dashboard__status-selector selector"
                solo
                dense
                hide-details
                :items="
                  updatedStatusesMode ? updatedAllowedStatuses : statusOptions
                "
                item-text="value"
                :loading="statusOptionsLoading"
                :disabled="showTableLoader || isTableUpdating"
                @input="handleBulkStatusSelect"
              ></v-autocomplete>
            </div>
          </div>
          <div class="ml-2">
            <span class="overline"> Inquiry </span>
            <div class="d-flex justify-center align-end">
              <v-btn
                width="38px"
                height="38px"
                fab
                elevation="4"
                color="accent"
                :disabled="
                  isTableUpdating ||
                  !selectedDashboardRows.every(
                    (el) => el.project.name === 'iTunes FC'
                  )
                "
                @click="callBulkInquiry"
                ><v-icon>mdi-slack</v-icon></v-btn
              >
            </div>
          </div>
          <div v-if="isAdminMode" class="ml-2">
            <span class="overline"> Edit </span>
            <div>
              <v-btn
                min-width="42px"
                height="38px"
                class="px-0"
                color="secondary"
                :disabled="!filterProject"
                :loading="staffLoading"
                @click="callBulkEdit"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-if="isAdminMode" class="ml-2">
            <span class="overline"> Delete </span>
            <div>
              <v-dialog v-model="bulkDeleteConfirm" persistent max-width="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    min-width="42px"
                    height="38px"
                    class="px-0"
                    color="secondary"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="bulkQueryEdit"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5 justify-center">
                    Are you sure?
                  </v-card-title>
                  <v-card-text class="text-center"
                    >Selected records will be premanently deleted.</v-card-text
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red darken-1"
                      text
                      :loading="bulkDeleteProcessing"
                      @click="bulkDeleteConfirm = false"
                    >
                      No
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      :loading="bulkDeleteProcessing"
                      @click="callBulkDelete"
                    >
                      Yes
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </div>
      </div>

      <!-- right -->
      <div class="d-flex flex-column justify-start align-end my-0">
        <!-- search -->
        <span class="overline" :style="{ opacity: 0, userSelect: 'none' }"
          >Search</span
        >
        <div>
          <v-badge
            :content="laptopSmaller ? '...' : filterSearchBadge"
            :value="filterSearch && !expandMenu"
            color="secondary"
            overlap
          >
            <v-btn
              height="38px"
              min-width="42px"
              class="elevation-2 px-0"
              outlined
              color="accent"
              @click="expandMenu = !expandMenu"
            >
              <v-icon v-if="expandMenu">mdi-magnify-minus-outline</v-icon>
              <v-icon v-else>mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </v-badge>
          <v-expand-x-transition>
            <v-text-field
              :style="{ position: 'absolute', bottom: '0', right: '60px' }"
              v-show="expandMenu"
              v-model="filterSearch"
              class="assignment-dashboard__search-selector selector"
              solo
              dense
              hide-details
              clearable
              :disabled="showTableLoader || isTableUpdating"
              @click:clear="applySearch(true)"
              @keydown.enter="applySearch(false)"
            />
          </v-expand-x-transition>
        </div>
      </div>
    </div>
    <!-- table -->
    <v-card class="assignment-dashboard__table-card">
      <v-skeleton-loader
        v-if="showTableLoader || (isAdminMode ? dropdownsLoading : false)"
        type="table"
        height="100vh"
      ></v-skeleton-loader>
      <ADTable
        ref="dashboardTable"
        v-if="
          tableData &&
          !showTableLoader &&
          (isAdminMode ? !dropdownsLoading : true)
        "
        :tableData="tableData"
        :filterShakespeare="filterShakespeare === 0 ? 1 : 0"
        :serverItemsLength="serverItemsLength"
        :isAdminMode="isAdminMode"
        :isTableUpdating="isTableUpdating"
        :statuses="statusOptions"
        :statusesLoading="statusOptionsLoading"
        :showEarnings="earningsMode === 0 ? 1 : 0"
        :filterProject="filterProject"
        :tabledataAmount="tabledataAmount"
        @update-allowed-statuses="handleAllowedStatuses"
        @refresh-request="refreshTableData"
      />
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// libraries
import _ from "lodash";
// internal
import {
  getAssignments,
  processAssignment,
  deleteAssignment,
  getAllStaff,
  getAllProjects,
  getEnum,
  getTasks,
  processTask,
} from "@/utils/newDbUtils";
import { customBreakpoint, timeUnits } from "@/utils/mixins";
import { STATUS_COLORS, ROUTES } from "@/utils/constants";
import { BLANK_NEW_ASSIGNMENT_DATAS } from "@/utils/assignmentUtils";
import SHK from "@/assets/SHK.svg";
import { generateInvoice } from "@/utils/staffInvoiceUtils";
// components
import ADTable from "@/components/AssignmentDashboard/ADTable";
import ADNewAssignmentModal from "@/components/AssignmentDashboard/ADNewAssignmentModal";
import ADHoursSubmission from "@/components/AssignmentDashboard/ADHoursSubmission.vue";

export default {
  name: "AssignmentDashboard",
  components: {
    ADTable,
    ADNewAssignmentModal,
    ADHoursSubmission
  },
  data() {
    return {
      SHK,
      bulkStatusValue: "",
      showTableLoader: true,
      tableData: null,
      serverItemsLength: 0,
      filterEmail: false,
      filtersDialog: false,
      ppm: null,
      ppy: null,
      filterDeliveryDate: null,
      filterRequestDate: null,
      filterSearch: null,
      expandMenu: false,
      filterProject: null,
      filterShakespeare: null,
      dropdownsLoading: true,
      staffLoading: false,
      staffList: [],
      filterPerson: null,
      roles: ["Primary", "Secondary", "QC"],
      filterRole: null,
      filterGeo: null,
      filterMediaType: null,
      filterRequestType: null,
      filterStatus: null,
      isTableUpdating: false,
      statusOptions: [],
      updatedAllowedStatuses: [],
      mediaTypesLoading: true,
      requestTypesLoading: true,
      statusOptionsLoading: true,
      updatedStatusesMode: false,
      earningsMode: null,
      itemsPerPage: 50,
      page: 0,
      sortBy: "schedule_deliveryDate",
      sorting: "desc",
      staffSort: {
        sortByDueTime: false,
        sortByAssignmentTitle: null,
        sortByAssignmentDetail: null,
        sortByWordCount: null,
        sortByFileLocation: null,
        sortByAssignmentStatus: null,
      },
      bulkDeleteConfirm: false,
      bulkDeleteProcessing: false,
      closeModalConfirmation: false,
      notActivePONumber: null,
      closePOConfirmation: false,
      tabledataAmount: 0,
      bulkQueryEditLoading: false,
      countFieldsToUpdate: 0,
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "isAdmin", "isManager"]),
    ...mapGetters("spreadsheetUpload", ["selectedDashboardRows"]),
    ...mapGetters("assignment", [
      "isEditMode",
      "newAssignmentDatas",
      "newAssignmentModalOpen",
      "geoOptions",
      "mediaTypeOptions",
      "requestTypeOptions",
      "projectsList",
      "bulkQueryEdit",
      "updateBatchConfirmation",
      "hoursSubmissionModalOpen"
    ]),
    updateBatchConfirmationModal: {
      get() {
        return this.updateBatchConfirmation;
      },
      set() {
        this.setUpdateBatchConfirmation(!this.updateBatchConfirmation);
      },
    },
    requestedView() {
      return this.$route.path === "/requested-assignments";
    },
    formattedRequestDate: {
      get() {
        return this.formatPickerDate(this.filterRequestDate);
      },
      set() {
        this.filterRequestDate = null;
      },
    },
    formattedDeliveryDate: {
      get() {
        return this.formatPickerDate(this.filterDeliveryDate);
      },
      set() {
        this.filterDeliveryDate = null;
      },
    },
    bulkQueryEditMode: {
      get() {
        return this.bulkQueryEdit;
      },
      set() {
        this.setBulkQueryEdit(!this.bulkQueryEdit);
      },
    },
    isAdminMode() {
      return !this.filterEmail;
    },
    filterSearchBadge() {
      return this.filterSearch?.length <= 6
        ? this.filterSearch
        : `${this.filterSearch?.slice(0, 6)}...`;
    },
    filtersBadge() {
      return Object.entries(this.$route.query).filter(
        (el) =>
          el[1] !== undefined &&
          (typeof el[1] === "object" && el[1] !== null
            ? el[1].length === 1
            : el[1] !== null)
      ).length;
    },
    query() {
      return {
        page: this.page,
        size: this.itemsPerPage,
        sort:
          this.isAdminMode && this.earningsMode !== 0
            ? `${this.sortBy},${this.sorting}`
            : null,
        sortByDueTime:
          this.isAdminMode && this.earningsMode !== 0
            ? null
            : this.staffSort.sortByDueTime,
        sortByAssignmentTitle:
          this.isAdminMode && this.earningsMode !== 0
            ? null
            : this.staffSort.sortByAssignmentTitle,
        sortByAssignmentDetail:
          this.isAdminMode && this.earningsMode !== 0
            ? null
            : this.staffSort.sortByAssignmentDetail,
        sortByWordCount:
          this.isAdminMode && this.earningsMode !== 0
            ? null
            : this.staffSort.sortByWordCount,
        sortByFileLocation:
          this.isAdminMode && this.earningsMode !== 0
            ? null
            : this.staffSort.sortByFileLocation,
        sortByAssignmentStatus:
          this.isAdminMode && this.earningsMode !== 0
            ? null
            : this.staffSort.sortByAssignmentStatus,
        search: this.filterSearch,
        requestDate: this.filterRequestDate,
        deliveryDate:
          this.isAdminMode && this.earningsMode !== 0
            ? this.filterDeliveryDate
            : null,
        dueDate:
          this.isAdminMode && this.earningsMode !== 0
            ? null
            : this.filterDeliveryDate,
        ppy: this.ppy,
        ppm: this.ppm?.toUpperCase(),
        geo: this.filterGeo,
        mediaType: this.filterMediaType,
        requestType: this.filterRequestType,
        projectId: this.filterProject?.id,
        personId:
          this.isAdminMode && this.earningsMode !== 0
            ? this.filterPerson?.user?.id
            : null,
        personRole:
          this.filterRole === "Creator"
            ? "createdBy"
            : this.filterRole?.toLowerCase(),
        fileLocation: this.filterShakespeare === 0 ? "Shakespeare" : null,
        assignmentStatus: this.requestedView ? "Requested" : this.filterStatus,
      };
    },
    totalEarnings() {
      return this.tableData
        ?.reduce(
          (a, b) =>
            a +
            (this.isActiveUserEmail(b.primary?.email)
              ? b.pricing.primaryTotal
              : this.isActiveUserEmail(b.secondary?.email)
              ? b.pricing.secondaryTotal
              : 0),
          0
        )
        ?.toFixed(2);
    },
  },
  mixins: [customBreakpoint, timeUnits],
  watch: {
    updateBatchConfirmationModal(newValue) {
      if (newValue) this.countFieldsToUpdate = this.countPTags();
    },
    filterPerson(newValue) {
      if (this.isAdminMode && newValue === null) this.filterRole = "";
    },
    filterSearch(newValue) {
      const currentQuery = this.$route.query;
      const newQuery =
        newValue !== ""
          ? {
              ...currentQuery,
              Search: newValue,
            }
          : _.omit(currentQuery, ["Search"]);

      this.$router
        .push({
          path: `/${
            ROUTES[
              this.requestedView
                ? "requestedAssignments"
                : "assignmentDashboard"
            ]
          }`,
          query: newQuery,
        })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
    requestedView() {
      this.clearFilter();
    },
    'newAssignmentDatas.0': {
      handler(newValue, oldValue) {
        if (newValue?.pricing?.purchaseOrder?.purchaseOrderNumber !== oldValue?.pricing?.purchaseOrder?.purchaseOrderNumber
          && !newValue?.pricing?.purchaseOrder?.isActive
          && oldValue?.pricing?.purchaseOrder
          && newValue?.pricing?.purchaseOrder) {
            this.notActivePONumber = newValue?.pricing?.purchaseOrder?.purchaseOrderNumber;
            this.closePOConfirmation = true;
          }
      },
      immediate: false,
    }
  },
  mounted() {
    this.handleQuery();
    this.getStatuses();
    this.getMediaTypes();
    this.getRequestTypes();
    this.handleDropdowns();
  },
  methods: {
    ...mapActions("spreadsheetUpload", ["setSelectedDashboardRows"]),
    ...mapActions("assignment", [
      "setNewAssignmentModalOpen",
      "setCommonData",
      "setBulkEditing",
      "setBulkQueryEdit",
      "setUpdateBatchConfirmation",
      "setHoursSubmissionModalOpen"
    ]),
    ...mapActions("flashMessage", ["handleFlash"]),
    countPTags() {
      const modal = this.$refs.newAssignmentModal.$el;
      const pTags = modal.querySelectorAll('p');
      const filteredPTags = Array.from(pTags).filter(p => p.textContent.trim() === 'Value was changed');
      return filteredPTags.length;
    },
    updateBatchAssignments() {
      this.bulkQueryEditLoading = true;
      this.$refs.newAssignmentModal.handleAssignmentsUpdate();
      this.bulkQueryEditLoading = false;
      this.setUpdateBatchConfirmation(false);
    },
    fullName(item) {
      return `${item.user.firstName} ${item.user.lastName}`;
    },
    applySearch(reset) {
      if (reset) this.filterSearch = null;
      this.handleFilterQuery("Search", this.filterSearch);
    },
    handleDueValue(dueTime) {
      return dueTime
        ? new Date(
            `${dueTime[1]}/${dueTime[2]}/${dueTime[0]} ${this.$moment(
              dueTime[3],
              "hh:mm"
            ).format("hh:mm A")}`
          )
        : null;
    },
    getTableData(hardReload) {
      if (hardReload) this.showTableLoader = true;
      else this.isTableUpdating = true;
      getAssignments({
        query: this.query,
        staffId:
          this.isAdminMode && this.earningsMode !== 0
            ? null
            : this.user.user.id,
      }).then((resp) => {
        if (resp?.status > 204) {
          this.tableData = [];
          this.handleFlash({ response: resp, show: true });
        } else {
          this.tabledataAmount = resp.totalElements;
          this.tableData = resp.content.map((el, i) => ({
            ...el,
            dueTime:
              this.isAdminMode && this.earningsMode !== 0
                ? null
                : this.handleDueValue(el.schedule.dueTime),
            dueGroup:
              this.isAdminMode && this.earningsMode !== 0
                ? null
                : this.$moment(this.handleDueValue(el.schedule.dueTime)).format(
                    "YYYY-MM-DD"
                  ),
            checked: false,
            index: i,
          }));
        }
        this.serverItemsLength = resp?.totalElements ?? 0;
        this.showTableLoader = this.isTableUpdating = false;
      });
    },
    refreshTableData(options) {
      const sortKey = options.sortBy[0];
      const sortDir = options.sortDesc[0];
      const handleStaffSort = (key) => {
        const capitalizeKey = () => {
          const extractedKey =
            key === "dueTime" ? key : key.split("[")[1].slice(0, -1);
          return extractedKey.charAt(0).toUpperCase() + extractedKey.slice(1);
        };
        const handleDir = () => {
          if (sortDir === undefined || sortDir) return key !== "dueTime";
          else return key === "dueTime";
        };
        Object.keys(this.staffSort).forEach((el) => {
          this.staffSort[el] =
            el === `sortBy${capitalizeKey()}` ? handleDir() : null;
        });
      };
      const handleSortKey = () => {
        return sortKey === undefined
          ? "schedule_deliveryDate"
          : `${sortKey.split("[")[0]}_${sortKey.split("[")[1].slice(0, -1)}`;
      };
      this.itemsPerPage =
        options.itemsPerPage === -1
          ? this.serverItemsLength
          : options.itemsPerPage;
      this.page = options.page - 1;
      if (this.isAdminMode && this.earningsMode !== 0) {
        this.sortBy = handleSortKey();
        this.sorting = sortDir === undefined || sortDir ? "desc" : "asc";
      } else handleStaffSort(sortKey ?? "dueTime");
      this.getTableData();
    },
    handleFilterQuery(param, data) {
      const query = {
        ...this.$route.query,
        [param]:
          param === "Person" && data
            ? `${data.user.firstName} ${data.user.lastName}`
            : param === "Project" && data
            ? data?.name
            : data,
      };

      this.$router
        .push({
          path: `/${
            ROUTES[
              this.requestedView
                ? "requestedAssignments"
                : "assignmentDashboard"
            ]
          }`,
          query,
        })
        .catch(() => {}); // Avoided redundant navigation error handler

      this[param === "ppy" || param === "ppm" ? param : `filter${param}`] =
        data;

      if (param === "ppm" && !this.ppy) return;

      if (this.$refs.dashboardTable?.options?.page === 1) this.getTableData();
      else this.$refs.dashboardTable.options.page = 1;
    },
    clearFilter(date) {
      let query = this.$route.query;

      if (date) {
        query = _.omit(query, [date]);
        this[`filter${date}`] = null;
      } else {
        this.filterShakespeare =
          this.filterProject =
          this.filterRole =
          this.filterStatus =
          this.filterGeo =
          this.filterMediaType =
          this.filterRequestType =
          this.filterSearch =
          this.ppm =
          this.ppy =
            null;
        this.filterPerson =
          this.isAdminMode && this.earningsMode === 0
            ? this.filterPerson
            : null;
        query = {};
      }

      if (!date) this.filterRequestDate = this.filterDeliveryDate = null;

      this.$router
        .push({
          path: `/${
            ROUTES[
              this.requestedView
                ? "requestedAssignments"
                : "assignmentDashboard"
            ]
          }`,
          query,
        })
        .catch(() => {}); // Avoided redundant navigation error handler

      if (this.$refs.dashboardTable?.options?.page === 1) this.getTableData();
      else this.$refs.dashboardTable.options.page = 1;
    },
    handleBulkStatusSelect(status) {
      this.isTableUpdating = true;
      const bulkStatusUpdate = this.selectedDashboardRows.map((el) =>
        processAssignment({
          data: {
            assignmentStatus: status,
          },
          id: el.id,
          patch: true,
        })
      );
      Promise.all(bulkStatusUpdate)
        .then((resp) => {
          const failedUpdates = resp.filter((el) => el.status > 204);
          if (failedUpdates.length) {
            const errMsg = failedUpdates
              .map(
                (el) => `${JSON.parse(el.config.id)} / Error: ${el.data.error}`
              )
              .join(".\n");
            this.handleFlash({
              response: {
                data: {
                  statusCode: 500,
                  error: `Failed to update statuses of assignments with IDs:\n${errMsg}`,
                },
              },
              show: true,
            });
          } else {
            // handle Task status on bulk Assignment status change
            if (status.endsWith("Complete")) {
              const cueIDs = [
                ...new Set(
                  this.selectedDashboardRows.map((el) => el.cueTaskId)
                ),
              ];
              cueIDs.forEach((cueID) => {
                getAssignments({ query: { search: cueID } }).then(
                  (assgnResp) => {
                    const assgnsWithCueIdField = assgnResp.content.filter(
                      (el) => el.cueTaskId === cueID
                    );
                    if (
                      assgnsWithCueIdField.every(
                        (el) => el.assignmentDetails.assignmentStatus === status
                      )
                    ) {
                      getTasks({ query: { search: cueID } }).then(
                        (taskResp) => {
                          const task = taskResp.content.find(
                            (el) => el.cueTaskId === cueID
                          );
                          if (task)
                            processTask({
                              data: {
                                taskStatus: status,
                              },
                              id: task.id,
                              patch: true,
                            }).then((resp) =>
                              this.handleFlash({ response: resp, show: true })
                            );
                        }
                      );
                    }
                  }
                );
              });
            }
            this.handleFlash({ response: { status: 200 }, show: true });
          }
          this.isTableUpdating = false;
          this.bulkStatusValue = "";
          this.getTableData(true);
          this.setSelectedDashboardRows([]);
        })
        .catch((err) => {
          console.error(err);
          this.handleFlash({ response: err, show: true });
          this.isTableUpdating = false;
        });
    },
    getStatuses() {
      getEnum("AssignmentStatus").then((data) => {
        this.statusOptions = data.map((el) => ({
          value: el,
          color: STATUS_COLORS.find((color) => color.statuses.includes(el))
            ?.value,
        }));
        this.setCommonData({ field: "statusOptions", data: data });
        this.statusOptionsLoading = false;
      });
    },
    getMediaTypes() {
      getEnum("MediaType").then((data) => {
        this.setCommonData({ field: "mediaTypeOptions", data });
        this.mediaTypesLoading = false;
      });
    },
    getRequestTypes() {
      getEnum("RequestType").then((data) => {
        this.setCommonData({ field: "requestTypeOptions", data });
        this.requestTypesLoading = false;
      });
    },
    handleRoles() {
      return new Promise((resolve, reject) => {
        resolve(this.roles.push("Creator"));
      });
    },
    handleStaff() {
      this.staffLoading = true;
      getAllStaff().then((data) => {
        this.staffList = data;
        const driList = data.filter((el) => el.user.userRole === "ADMIN");
        this.setCommonData({
          field: "driList",
          data: driList,
        });
        this.staffLoading = false;
      });
    },
    handleDropdowns() {
      if (this.isAdminMode) {
        let dropdowns = [getEnum("GEO"), getAllProjects(), this.handleRoles()];
        if (this.$route.query?.Person) dropdowns.push(getAllStaff());
        Promise.all(dropdowns)
          .then((resp) => {
            this.setCommonData({ field: "geoOptions", data: resp[0] });
            this.setCommonData({ field: "projectsList", data: resp[1] });
            if (this.$route.query?.Project || this.$route.query?.Person) {
              if (this.$route.query?.Project)
                this.filterProject = this.projectsList.find(
                  (el) => this.$route.query.Project === el.name
                );
              if (this.$route.query?.Person) {
                this.staffList = resp[3];
                this.filterPerson = this.staffList.find(
                  (el) =>
                    this.$route.query.Person.includes(el.user.firstName) &&
                    this.$route.query.Person.includes(el.user.lastName)
                );
              }
              this.handleUser();
            }
            this.dropdownsLoading = false;
          })
          .catch((err) => {
            console.error(err);
            this.dropdownsLoading = false;
          });
      } else this.dropdownsLoading = false;
    },
    handleQuery() {
      let query = { ...this.$route.query };

      if (_.isEmpty(query) && !this.requestedView) {
        this.ppm = this.$moment().format("MMMM");
        this.ppy = this.$moment().format("YYYY");
        query = {
          ppm: this.ppm,
          ppy: this.ppy,
        };
      } else {
        Object.keys(query).forEach((param) => {
          this[param === "ppy" || param === "ppm" ? param : `filter${param}`] =
            param === "Shakespeare" ? Number(query[param]) : query[param];
        });
      }

      this.$router
        .push({
          path: `/${
            ROUTES[
              this.requestedView
                ? "requestedAssignments"
                : "assignmentDashboard"
            ]
          }`,
          query,
        })
        .catch(() => {}); // Avoided redundant navigation error handler

      if (!query?.Person) {
        if (!query?.Project) this.handleUser();
        if (this.isAdminMode) this.handleStaff();
      }
    },
    handleUser() {
      this.filterEmail =
        this.isAdmin || this.isManager ? false : this.user.user.email;
      this.getTableData(true);
    },
    handleAllowedStatuses(statuses) {
      this.updatedAllowedStatuses = [...statuses];
      this.updatedStatusesMode = statuses.length;
    },
    callBulkInquiry() {
      this.$refs.dashboardTable.callBulkInquiry();
    },
    isActiveUserEmail(email) {
      return email === this.user.user.email;
    },
    downloadInvoice() {
      generateInvoice({
        assignments: this.tableData,
        person: this.user,
        total: this.totalEarnings,
        filters: this.$route.query,
      });
    },
    callBulkEdit() {
      this.setBulkEditing(true);
      this.setNewAssignmentModalOpen(true);
    },
    callBulkDelete() {
      this.bulkDeleteProcessing = true;
      const deletePromises = this.selectedDashboardRows.map((el) =>
        deleteAssignment(el.id)
      );
      Promise.all(deletePromises).then((resp) => {
        // process the array of API respones for flash message
        const rejected = resp.filter((el) => el.status >= 400);
        let rejectedResponse = null;
        if (rejected.length) {
          const errMsg = rejected.map((el) => el.data.message).toString();
          rejectedResponse = { data: { statusCode: 500, message: errMsg } };
        }
        this.handleFlash({
          response: rejectedResponse ?? { data: { statusCode: 200 } },
          show: true,
        });
        this.bulkDeleteConfirm = this.bulkDeleteProcessing = false;
        this.setSelectedDashboardRows([]);
        this.getTableData(true);
      });
    },
    closeModal() {
      if (this.isEditMode) {
        const project = this.tableData.find(el => el.id === this.newAssignmentDatas[0].id);
        if (_.isEqual(project.assignmentDetails, this.newAssignmentDatas[0].assignmentDetails) &&
          _.isEqual(project.pricing, this.newAssignmentDatas[0].pricing)) {
          this.closeModalConfirmation = false;
          this.setNewAssignmentModalOpen(false);
        } else {
          this.closeModalConfirmation = true;
        }
        return
      }
      if (_.isEqual(BLANK_NEW_ASSIGNMENT_DATAS, this.newAssignmentDatas)) {
        this.closeModalConfirmation = false;
        this.setNewAssignmentModalOpen(false);
      } else {
        this.closeModalConfirmation = true;
      }
    },
    closeAssignmentModal() {
      this.closeModalConfirmation = false;
      this.setNewAssignmentModalOpen(false);
    },
    removePOFromForm() {
      this.newAssignmentDatas[0].pricing.purchaseOrder = null;
      this.closePOConfirmation = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.assignment-dashboard {
  max-width: 1500px;
  margin: 0 auto;

  &__month-selector {
    width: 120px;
    &.laptop {
      width: 65px;
    }
    &.popup {
      width: 150px;
    }
  }

  &__year-selector {
    width: 80px;
    &.laptop {
      width: 75px;
    }
    &.popup {
      width: 80px;
    }
  }

  &__date-selector {
    width: 130px;
    &.laptop {
      width: 120px;
    }
    &.popup {
      width: 150px;
    }
  }

  &__project-selector {
    width: 200px;
    &.laptop {
      width: 180px;
    }
    &.laptopSmaller {
      width: 150px;
    }
    &.popup {
      width: 250px;
    }
  }

  &__person-selector {
    width: 170px;
    &.laptop {
      width: 160px;
    }
    &.laptopSmaller {
      width: 130px;
    }
    &.popup {
      width: 230px;
    }
  }

  &__role-selector {
    width: 110px;
    &.laptopSmaller {
      width: 80px;
    }
  }

  &__geo-selector {
    width: 100px;
    &.laptop {
      width: 80px;
    }
  }

  &__status-selector {
    width: 160px;
    &.popup {
      width: 200px;
    }
  }

  &__search-selector {
    width: 340px;
    &.popup {
      width: 450px;
    }
  }
}

.icon {
  font-size: 16px;
  margin-right: 15px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
}

.text {
  white-space: nowrap;
  padding-right: 15px;
  margin-bottom: 4px;
}

.vertical-middle {
  vertical-align: middle;
}

::v-deep {
  .selector.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 0;
  }
  .selector.v-text-field.v-text-field--solo .v-input__append-inner {
    padding-left: 0;
  }
  .selector.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
  }
  .selector.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 2px 0;
  }
  .filters {
    position: absolute;
    top: 20px;
  }
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .v-application .overline {
    font-size: 0.6rem !important;
  }
}
</style>
