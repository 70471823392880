<template>
  <v-app>
    <NavBar />
    <FlashMessage />
    <v-dialog
      max-width="460"
      persistent
      v-model="showAddressUpdateWindow"
    >
      <div class="address-update">
        <h6 class="address-update--title">Action Needed: Update Address</h6>
        <p class="address-update--text">Please review and update your address details by clicking the button below.</p>
        <v-btn class="address-update--btn" color="#1976D2" @click="handleAddressUpdate">Update Address</v-btn>
      </div>
    </v-dialog>
    <v-main
      :class="{ 
        'grey lighten-4': $route.name !== 'Reimbursement Request Form' 
          || $route.name !== 'Request an Assignment'
          || $route.name !== 'Vendor Invoice Form',
        'grey darken-2': $route.name === 'Reimbursement Request Form' 
          || $route.name === 'Request an Assignment'
          || $route.name === 'Vendor Invoice Form'
        }">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// internal
import "@/styles/styles.scss";
import { validateToken, refreshSession } from "@/utils/axiosInstance";
// components
import NavBar from "@/components/ui/NavBar";
import FlashMessage from "@/components/ui/FlashMessage";

export default {
  name: "App",
  components: { NavBar, FlashMessage },
  computed: {
    ...mapGetters("auth", ["user", "profileModal", "showAddressUpdateWindow"]),
  },
  watch: {
    profileModal() {
      if (!this.profileModal) {
        const token = localStorage.getItem("token");
        validateToken(token).then((validResp) => {
          if (validResp.status === 200) {
            this.setUser(validResp.data);
            if (
              !this.user.hrDetails?.streetAddress ||
              !this.user.hrDetails?.city ||
              !this.user.hrDetails?.zipCode
            ) {
              this.setShowAddressUpdateWindow(true);
            } else {
              this.setShowAddressUpdateWindow(false);
            }
          }
        });
      }
    }
  },
  methods: {
    ...mapActions("auth", [
      "setIsAdmin",
      "setIsManager",
      "setUser",
      "setIsDubEditor",
      "handleProfileModal",
      "setProfileModalTab",
      "setProfileModalIsViewMode",
      "setShowAddressUpdateWindow"
    ]),
    ...mapActions("flashMessage", ["handleFlash"]),
    handleAddressUpdate() {
      this.setProfileModalTab(2);
      this.setProfileModalIsViewMode(false);
      this.handleProfileModal(true);
      this.setShowAddressUpdateWindow(false);
    },
    dropSession() {
      this.setUser(null);
      this.setIsAdmin(false);
      this.setIsManager(false);
      this.setIsDubEditor(false);
    },
  },
  mounted() {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const handleAuth = (auth) => {
      this.setIsAdmin(auth.data.user.userRole === "ADMIN");
      this.setIsManager(auth.data.user.userRole === "MANAGER");
      this.setIsDubEditor(!!auth.data?.adminDetails?.dubEditorAccess);
      this.setUser(auth.data);
      if (this.user.hrDetails?.userRole !== "CLIENT" || !this.user.adminDetails?.vendor) {
        if (
          !this.user.hrDetails?.streetAddress ||
          !this.user.hrDetails?.city ||
          !this.user.hrDetails?.zipCode
        ) this.setShowAddressUpdateWindow(true);
      }
      if (this.$route.path === "/login") {
        if (this.$route?.query?.to) this.$router.push(this.$route.query.to);
        else this.$router.push("/");
      }
    };
    if (!token) {
      this.dropSession();
      if (this.$route.path !== "/login") this.$router.push("/login");
    } else {
      validateToken(token).then((validResp) => {
        if (validResp.status === 200) {
          handleAuth(validResp);
        } else {
          refreshSession(refreshToken).then((refResp) => {
            if (refResp.status === 200) {
              localStorage.setItem("token", refResp.data.accessToken);
              validateToken(refResp.data.accessToken).then((refResp) => {
                handleAuth(refResp);
              });
            } else {
              this.dropSession();
              if (this.$route.path !== "/login") this.$router.push("/login");
              this.handleFlash({ response: refResp, show: true });
            }
          });
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.address-update {
  width: 100%;
  background-color: #FFF;
  padding: 15px;
  &--title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
  }
  &--text {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 0;
  }
  &--btn {
    width: 100%;
    margin-top: 35px;
    color: #FFF !important;
  }
}
</style>
