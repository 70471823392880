<template>
  <v-card class="d-flex flex-column hours-submission">
    <div class="hours-submission__container">
      <div class="d-flex align-center hours-submission__container--title">
        <v-icon color="#000">mdi-clock-outline</v-icon>
        <h6 class="ml-4">Log Work Time</h6>
      </div>
      <div class="d-flex flex-column hours-list">
        <div class="d-flex hours-list__item">
          <div class="hours-list__item-main">
            <v-row class="mt-1">
              <v-col cols="12" sm="4" class="py-0">
                <v-menu
                  v-model="logDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedLogDate"
                      label="Date"
                      prepend-inner-icon="mdi-calendar"
                      outlined
                      readonly
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @input="logDateMenu = false"
                    v-model="logDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field
                  outlined
                  label="Worked Hours"
                  type="number"
                  hide-details
                  v-model="workedHours"
                  :rules="[hoursRule]"
                  @input="validateHours"
                />
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-combobox
                  outlined
                  label="Worked Minutes"
                  hide-details
                  :items="workedMinutesList"
                  v-model="workedMinutes"
                  :rules="[minutesRule]"
                  @change="validateMinutes"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row class="mt-20px">
              <v-col cols="12" class="pb-0 pt-0">
                <v-text-field
                  outlined
                  rows="1"
                  hide-details
                  label="Note"
                  v-model="note"
                />
              </v-col>
            </v-row>
          </div>
          <div class="d-flex align-center justify-center hours-list__item-remove">
            <v-btn
              fab
              small
              text
            >
              <v-icon color="#757575">
                mdi-delete
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="d-flex align-center justify-space-between hours-list__total">
          <div class="d-flex align-center hours-list__total--time">
            <p class="mb-0 hours-list__total--time-text">Total:</p>
            <p class="mb-0 ml-2 hours-list__total--time-chip">0h 0m</p>
            <p class="mb-0 ml-2 hours-list__total--time-text2">for</p>
            <p class="mb-0 ml-2 hours-list__total--time-chip">05 May 2024 - 08 May 2024</p>
          </div>
          <div class="d-flex align-center">
            <v-btn
              class="mx-2"
              fab
              dark
              x-small
              color="#1976D2"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-end hours-submission__action">
      <v-btn 
        text
        @click="setHoursSubmissionModalOpen(false)"
        >
        Cancel
      </v-btn>
      <v-btn
        depressed
        color="#1976D2"
        class="request-btn white--text ml-2"
      >
        Submit Time
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ADHoursSubmission",
  data() {
    return {
      logDateMenu: false,
      logDate: null,
      workedHours: null,
      workedMinutesList: [0, 15, 30, 45],
      workedMinutes: null,
      note: null,
    };
  },
  computed: {
    formattedLogDate: {
      get() {
        return this.formatPickerDate(this.logDate);
      },
      set() {
        this.logDate = null;
      },
    },
  },
  methods: {
    ...mapActions("assignment", ["setHoursSubmissionModalOpen"]),
    formatPickerDate(date) {
      return date ? this.$moment(date).format("DD MMMM YYYY") : "";
    },
    hoursRule(value) {
      if (value === null || value === "") return true;
      if (value < 0) return "Worked hours cannot be less than 0";
      if (value > 24) return "Worked hours cannot be greater than 24";
      return true;
    },
    validateHours() {
      if (this.workedHours < 0) {
        this.workedHours = 0;
      } else if (this.workedHours > 24) {
        this.workedHours = 24;
      }
    },
    minutesRule(value) {
      if (value === null || value === "") return true;
      if (value < 0) return "Worked minutes cannot be less than 0";
      if (value > 60) return "Worked minutes cannot be greater than 60";
      return true;
    },
    validateMinutes() {
      if (this.workedMinutes < 0) {
        this.workedMinutes = 0;
      } else if (this.workedMinutes > 60) {
        this.workedMinutes = 60;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hours-submission {
  padding: 15px;
  background-color: #E0E0E0;
  &__container {
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
    &--title {
      margin-bottom: 30px;
      & h6 {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
      }
    }
    & .hours-list {
      &__total {
        margin-top: 32px;
        .v-btn--fab.v-size--small {
          width: 22px !important;
          height: 22px !important;
        }
        &--time {
          &-text {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;
            text-align: left;
          }
          &-text2 {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            text-align: left;
          }
          &-chip {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            text-align: left;
            background-color: #E0E0E0;
            border-radius: 8px;
            padding: 3px 12px 0 12px;
          }
        } 
      }
      &__item {
        &-main {
          width: 90%;
        }
        &-remove {
          width: 10%;
          margin-top: 10px;
        }
      }
    }
  }
  &__action {
    margin-top: 15px;
  }
}

.mt-20px {
  margin-top: 20px;
}
</style>