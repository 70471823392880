<template>
  <v-card class="activity-card"
    :style="{borderBottomLeftRadius: gIsEditing ? '15px' : '0px',
      boxShadow: 'none'
    }"
    >
    <v-card-title 
      style="
        font-size: 20px !important;
      "
      class="headline">
      Activity
    </v-card-title>
    <v-divider></v-divider>
    <div v-if="!gIsEditing" :style="dynamicHeightStyle" class="background-color empty-activity">
      <div>
        <p class="text-center mb-0 ">There is no activity yet.</p>
        <p class="text-center mb-0 ">Publish the task to view</p>
        <p class="text-center mb-0 ">activity and write comments.</p>
      </div>
    </div>
    <div v-if="gIsEditing" class="scrollable-col background-color" :style="dynamicHeightStyle" @scroll="handleScroll" ref="messageContainer">
      <div v-if="isLoading" class="text-center py-2">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div 
        v-for="(message, index) in messages" 
        :key="index" 
        class="m1-2 flex-column my-4" 
        style="align-items: flex-start;">
        <v-card class="w-100 comment-card" v-if="message.commentType === 'COMMENT'">
          <div class="message-top d-flex align-center justify-space-between">
            <div class="d-flex align-center">
              <v-avatar :color="getColorForInitials(getInitials(message.commentBy.firstName + ' ' + message.commentBy.lastName))" size="36">
                <span class="white--text">{{ getInitials(message.commentBy.firstName + ' ' + message.commentBy.lastName) }}</span>
              </v-avatar>
              <p class="sender mb-0 ml-1">{{ message.commentBy.firstName + ' ' + message.commentBy.lastName }}</p>
            </div>
            <div>
              <p class="timestamp mb-0">{{ formatDateAndTime(message.commentDateTime) }}</p>
            </div>
          </div>
          <div outlined style="width: 100%; margin-top: 10px !important;">
            <v-card-text class="text-body pa-1 mb-0" style="white-space: normal; word-wrap: break-word;">
              {{ message.comment }}
            </v-card-text>
          </div>
        </v-card>
        <div v-if="message.commentType === 'ACTION'" class="w-100 action-card">
          <div class="message-top flex-column w-100">
            <div class="d-flex align-center justify-space-between w-100">
              <p class="sender-auto mb-0">{{ message.commentBy.firstName + ' ' + message.commentBy.lastName }}</p>
              <p class="timestamp mb-0">{{ message.commentDateTime ? formatDateAndTime(message.commentDateTime) : '' }}</p>
            </div>
            <div>
              <p class="text-body mt-1 mb-0">{{ message.comment }}</p>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-divider v-if="gIsEditing" class="mb-2"></v-divider>
    
    <v-row v-if="gIsEditing" no-gutters style="padding: 10px !important; border-bottom-right-radius: 15px !important;">
      <v-col cols="12" class="d-flex relative">
          <v-textarea
            :style="{ overflowY: calculatedRows === 12 ? 'auto' : 'hidden', }"
            v-model="newMessage" 
            outlined
            solo
            :rows="calculatedRows"
            color="#00000061"
            hide-details
            @focus="showTextArea = true"
            @blur="showTextArea = false"
            name="input-7-4"
            label="Write a comment..." 
            :class="{'send-field-textarea-1': showTextArea || newMessage,
              'send-field-textarea': !showTextArea && !newMessage }"
          ></v-textarea>
        <button
          :class="{'send-button-textarea': showTextArea || newMessage,
            'send-button': !showTextArea && !newMessage }"
          :disabled="!gIsEditing || !newMessage"
          @click="addMessage">
          Send
        </button>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { getTaskComments, processTaskComment } from '@/utils/newDbUtils.js';
import { alphabetColors } from "@/utils/constants.js";

export default {
  name: 'PMDashboardFormActivity',
  props: {
    itemToEdit: {
      type: Object,
      required: true
    },
    taskId: {
      type: [String, Number],
      required: false
    }
  },
  data() {
    return {
      newMessage: '',
      messages: [],
      minHeight: 67,
      minWindowHeight: 330,
      showTextArea: false,
      page: 1,
      isLoading: false,
      totalElements: 0,
      totalPages: 0,
      hasMoreMessages: true,
    };
  },
  computed: {
    ...mapGetters("pmDashboard", ["gIsEditing"]),
    ...mapGetters("auth", ["user"]),
    dynamicHeightStyle() {
      const windowHeight = window.innerHeight;
      const calculatedHeight = Math.max(this.minHeight, windowHeight - (this.minWindowHeight - this.minHeight));
      if (!this.gIsEditing) {
        return {
          height: `${calculatedHeight + 89}px`,
        };
      }
      return {
        height: `${calculatedHeight}px`,
      };
    },
    calculatedRows() {
      if (this.showTextArea || this.newMessage) {
        const charCount = this.newMessage.length;
        const baseCharsPerRow = 60;
        const lineBreaks = (this.newMessage.match(/\n/g) || []).length;

        let rows = Math.floor(charCount / baseCharsPerRow) + 3 + lineBreaks;
        rows = Math.min(rows, 12);
        this.minWindowHeight = 330 + (rows - 3) * (490 - 330) / (12 - 3);

        return rows;
      } else {
        this.minWindowHeight = 330;
        return 1;
      }
    }
  },
  watch: {
    showTextArea() {
      if (this.showTextArea || this.newMessage) {
        this.minHeight = 7;
      } else {
        this.minHeight = 67;
      }
    },
    newMessage() {
      if (this.showTextArea || this.newMessage) {
        this.minHeight = 7;
      } else {
        this.minHeight = 67;
      }
    }
  },
  methods: {
    async loadMoreMessages() {
      if (this.isLoading || !this.hasMoreMessages) return;
      
      this.isLoading = true;
      try {
        const response = await getTaskComments(this.itemToEdit.id ? this.itemToEdit.id : this.taskId, {
          page: this.page + 1,
        });
        
        const newMessages = response.data.content.reverse();
        this.messages = [...newMessages, ...this.messages];
        
        this.page++;
        this.totalElements = response.data.totalElements;
        this.totalPages = response.data.totalPages;
        
        this.hasMoreMessages = this.page < this.totalPages;
        
        // Preserve scroll position
        const container = this.$refs.messageContainer;
        const oldHeight = container.scrollHeight;
        this.$nextTick(() => {
          const newHeight = container.scrollHeight;
          container.scrollTop = newHeight - oldHeight;
        });
      } catch (error) {
        console.error('Error loading more messages:', error);
      } finally {
        this.isLoading = false;
      }
    },
    handleScroll(event) {
      const container = event.target;
      if (container.scrollTop === 0 && this.hasMoreMessages) {
        this.loadMoreMessages();
      }
    },
    async initializeMessages() {
      this.page = 0;
      this.hasMoreMessages = true;
      if (this.gIsEditing && this.itemToEdit.id) {
        const response = await getTaskComments(this.itemToEdit.id, { page: 0 });
        this.totalElements = response.data.totalElements;
        this.totalPages = response.data.totalPages;
        this.messages = response.data.content.reverse();
        this.hasMoreMessages = this.page < this.totalPages - 1;
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      } else if (!this.itemToEdit?.id) {
        this.messages = [{
          taskId: null,
          comment: 'Created this task',
          commentBy: {
            firstName: this.user.user.firstName,
            lastName: this.user.user.lastName
          },
          commentType: "ACTION"
        }];
        this.hasMoreMessages = false;
      }
    },
    getInitials(name) {
      return name.split(' ').map(n => n[0]).join('');
    },
    formatDateAndTime(commentDateTime) {
      const [year, month, day, hour, minute] = commentDateTime;

      const date = new Date(year, month - 1, day, hour, minute);

      const options = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      };
      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

      const finalFormattedDate = formattedDate.replace(',', '').replace(' AM', 'am').replace(' PM', 'pm');

      return finalFormattedDate;
    },
    getColorForInitials(initials) {
      return initials && initials[1] && alphabetColors.find((color) => color.character === initials[1])?.color || "#9747FF";
    },
    async addMessage() {
      if (this.newMessage.trim()) {
        await processTaskComment({
          taskId: this.itemToEdit.id ? this.itemToEdit.id : this.taskId,
          comment: this.newMessage,
          commentById: null,
          commentType: "COMMENT"
        });
        this.newMessage = '';
        await this.initializeMessages();
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    },
    scrollToBottom() {
      const container = this.$el.querySelector('.scrollable-col');
      container.scrollTop = container.scrollHeight;
    },
    updateHeight() {
      this.$forceUpdate();
    },
  },
  async mounted() {
    window.addEventListener('resize', this.updateHeight);
    await this.initializeMessages();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateHeight);
  },
};
</script>

<style>
.headline {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  padding: 10px;
  height: 40px !important;
  text-align: left !important;
  line-height: 23.44px !important;
}

.v-list-item-avatar {
  margin-top: auto;
  margin-bottom: auto;
}

.v-card-text {
  font-size: 12px;
}

.text-body-2 {
  font-size: 12px;
}

.text-caption {
  font-size: 10px;
}

.v-list-item {
  display: flex;
  background-color: #FFF !important;
}

.v-list-item-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.v-list-item-title,
.v-list-item-subtitle {
  max-width: 100%;
}

.sender {
  font-size: 16px;
  font-weight: 500 !important;
  color: #000;
  font-family: 'Roboto', sans-serif !important;
}

.sender-auto {
  font-size: 12px;
  font-weight: 400 !important;
  color: #949494;
  font-family: 'Roboto', sans-serif !important;
}

.timestamp {
  font-size: 12px;
  font-weight: 500 !important;
  color: #949494;
}

.text-body {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300 !important;
  color: #000;
  font-family: 'Roboto', sans-serif !important;
}

.send-button {
  background-color: #6ab3fc80 !important;
  padding: 10px 20px !important;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-53%);
  border-radius: 4px;
  color: #FFF !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  z-index: 9000;
  text-transform: uppercase !important;
  &:hover {
    background-color: #1976D280 !important;
    cursor: pointer;
  }
}
.send-button-textarea {
  background-color: #1976D2 !important;
  padding: 10px 20px !important;
  position: absolute;
  right: 12px;
  bottom: 11px;
  border-radius: 4px;
  color: #FFF !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  z-index: 9000;
  text-transform: uppercase !important;
  &:hover {
    cursor: pointer;
  }
}

.send-field input {
  padding-right: 90px !important;
}

.send-field-textarea .v-input__control .v-input__slot {
  padding-bottom: 12px !important;
}

.send-field-textarea textarea {
  resize: none !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 18px !important;
  letter-spacing: 0.0089em !important;
  text-align: left !important;
}

.send-field-textarea-1 textarea {
  resize: none !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 18px !important;
  letter-spacing: 0.0089em !important;
  text-align: left !important;
}

.send-field-textarea-1 .v-input__control .v-input__slot {
  padding-bottom: 50px !important;
}

.empty-activity {
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-activity div p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #B4B4B4;
}

.w-100 {
  width: 100% !important;
}

.comment-card {
  box-shadow: none !important;
  padding: 20px !important;
  border: 1px solid #E8EAED !important;
  border-radius: 10px !important;
  padding: 20px !important;
}

.action-card {
  padding: 5px 20px 5px 20px !important;
  margin-bottom: 10px !important;
}

.background-color {
  background-color: #FBFBFC !important;
  padding: 10px, 15px, 10px, 15px !important;
}

.scrollable-col {
  height: 330px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 7px;
}

/* WebKit browsers */
.scrollable-col::-webkit-scrollbar {
  width: 10px;
}

.send-field-textarea-1 textarea::-webkit-scrollbar {
  width: 5px;
}

.scrollable-col::-webkit-scrollbar-track, .send-field-textarea-1 textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable-col::-webkit-scrollbar-thumb, .send-field-textarea-1 textarea::-webkit-scrollbar-thumb {
  background: #ADB3BD;
  border-radius: 8px;
}

.scrollable-col::-webkit-scrollbar-thumb:hover, .send-field-textarea-1 textarea::-webkit-scrollbar-thumb:hover {
  background: #ADB3BD;
}

/* Firefox */
.scrollable-col {
  scrollbar-width: thin;
  scrollbar-color: #ADB3BD #f1f1f1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
