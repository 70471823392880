<template>
  <v-card class="pm-form">
    <div class="d-flex align-center justify-space-between pa-2 px-4 pmdashboard__title">
      <p class="pmdashboard__title--left">
        Task <span v-if="gIsEditing && checkIfFieldUpdated">[UNSAVED CHANGES]</span>
        <span v-if="!gIsEditing">[DRAFT]</span>
      </p>
      <div class="d-flex align-center pmdashboard__title--right">
        <p class="pmdashboard__title--right--text ml-5" v-if="gIsEditing && windowWidth > 925">
          Created on {{ createdDate && formatDateForTitle(createdDate) }}
          {{ createdDate && updatedDate && (updatedDate[0] !== createdDate[0] || updatedDate[1] !== createdDate[1] || updatedDate[2] !== createdDate[2]) ? ', last edited ' + formatDateForTitle(updatedDate) : '' }}
          {{ createdDate && updatedDate && (updatedDate[0] !== createdDate[0] || updatedDate[1] !== createdDate[1] || updatedDate[2] === createdDate[2]) ? ', last edited now' : '' }}
        </p>
        <p class="pmdashboard__title--right--text ml-5" v-if="!gIsEditing && windowWidth > 925">The task is in DRAFT. To save your changes, publish the task.</p>
        <button v-if="!gIsEditing && !createTaskLoader"
          class="pmdashboard__title--right--publish ml-2"
          @click.prevent="publishTask">
            Publish Task
        </button>
        <button v-else-if="gIsEditing && checkIfFieldUpdated && !createTaskLoader"
          class="pmdashboard__title--right--publish ml-2"
          @click.prevent="publishTask">
            Update
        </button>
        <div class="pmdashboard__title--right--delimetr ml-5"></div>
        <div class="pmdashboard__title--right--icon ml-5">
          <v-btn
            text
            small
            @click.native.stop.prevent="openSlackForm(itemToEdit)">
            <pmdashboard_table_icons
              :icon="'pmdashboard_slack'"
              />
          </v-btn>
        </div>
        <div class="pmdashboard__title--right--delimetr ml-5"></div>
        <div class="pmdashboard__title--right--close ml-2">
          <v-btn
            text
            small
            @click="closeForm">
            <pmdashboard_table_icons
              :icon="'pmdashboard_close_black'"
              />
          </v-btn>
        </div>
      </div>
    </div>
    <v-container class="pmdashboard__task pa-0" fluid>
      <div class="row-styles w-100 ma-0">
        <div
          :style="windowWidth <= 1286 ? { width: '100%' } : { width: 'calc(100% - 420px)' }"
          class="pmdashboard__task--content-left" 
          :class="{ 'pa-1': windowWidth > 1286,
            'pa-0': windowWidth <= 1286
           }">
          <div class="d-flex w-100">
            <div style="width: 100%;" 
              class="scrollable-col animated-section"
              v-show="settingsSection === 'Details'"
              :style="dynamicHeightStyle">
              <v-row class="d-flex flex-column pmdashboard__task--title pa-6" 
                style="
                margin-top: 0;
                padding-top: 50px !important;
                "
              >
                <div class="d-flex w-100">
                  <p class="task--id d-inline mr-2">ID: </p>
                  <div
                    v-if="!isEditing.includes('editableInput-idNumber')"
                    class="mb-0 task--id w-100 bradius-4"
                    :class="{
                      'hover': isHovered.includes('editableInput-idNumber'),
                      'empty-p-field-idNumber': !idNumber,
                      'fullfield-p-field-idNumber': idNumber
                    }"
                    style="position: relative;"
                    @mouseover="isHoveredOn('editableInput-idNumber')"
                    @mouseleave="isHoveredOff('editableInput-idNumber')"
                    @click="startEditing('editableInput-idNumber')"
                  >
                    <p style="width: 90%" class="mb-0">
                      {{ idNumber ? idNumber : '-' }}
                    </p>
                    <span
                      @click.prevent.stop="idNumber = ''"
                      class="pmdashboard__clear pmdashboard__clear--title"
                      v-if="isHovered.includes('editableInput-idNumber') && idNumber"
                    >
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_clear_text_field'"
                        class="pmdashboard__clear-text d-block"
                      />
                    </span>
                  </div>
                  <input
                    v-else
                    type="text"
                    ref="editableInput-idNumber"
                    v-model="idNumber"
                    @blur="stopEditing('editableInput-idNumber')"
                    @keydown.enter="stopEditing('editableInput-idNumber')"
                    :class="{ hover: true }"
                    class="input-field input-field-idNumber w-100 bradius-4"
                  />
                </div>
                <div
                  v-if="!isEditing.includes('editableInput-taskName')"
                  class="mb-0 task--title bradius-4"
                  :class="{
                    'hover': isHovered.includes('editableInput-taskName'),
                    'empty-p-field-taskName': !taskName,
                    'fullfield-p-field-taskName': taskName,
                    'error-color': publishIntent && !taskName
                  }"
                  style="position: relative;"
                  @mouseenter="isHoveredOn('editableInput-taskName')"
                  @mouseleave="isHoveredOff('editableInput-taskName')"
                  @click="startEditing('editableInput-taskName')"
                >
                  <p style="width: 90%" class="mb-0">
                    {{ taskName ? taskName : 'No Name' }}
                  </p>
                  <span
                    @click.stop.prevent="taskName = ''"
                    class="pmdashboard__clear pmdashboard__clear--title"
                    v-if="isHovered.includes('editableInput-taskName') && taskName"
                  >
                    <pmdashboard_table_icons
                      :icon="'pmdashboard_clear_text_field'"
                      class="pmdashboard__clear-text d-block"
                    />
                  </span>
                </div>
                <input
                  v-else
                  type="text"
                  ref="editableInput-taskName"
                  v-model="taskName"
                  @blur="stopEditing('editableInput-taskName')"
                  @keydown.enter="stopEditing('editableInput-taskName')"
                  :class="{ hover: true }"
                  class="input-field input-field-taskName w-100 bradius-4"
                />
                <div class="task--dri">
                  <p class="task--dri--text">Current DRI: </p>
                  <div class="task--dri--circle" 
                    v-if="currentDRI"
                    :style="{ 'background-color': getColorForInitials(currentDRI?.initials, currentDRI.name) }"
                    ></div>
                  <p class="task--dri--name" v-if="currentDRI">{{ currentDRI.name }}</p>
                  <p class="task--dri--name-dash" v-else> - </p>
                </div>
              </v-row>
              <v-row class="pmdashboard__task--content d-flex justify-space-between px-0 mx-0 ml-5">
                <div class="pmdashboard__task--content-left"
                  :class="{'pb-0': windowWidth <= 926}"
                  :style="windowWidth > 926 ? { 
                      marginRight: '15px',
                      width: 'calc(50% - 7.5px)'
                    } : { 
                      marginRight: '0',
                      width: '100%'
                    }">
                  <v-row class="pa-0 my-1 row-min-height">
                    <v-col cols="5" class="d-flex align-center pa-0 fixed-width-col">
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_status'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        />
                      <p class="pmdashboard__task--content-left--text mb-0">Status</p>
                    </v-col>
                    <v-col class="pa-0 pr-5">
                      <v-menu offset-y :close-on-content-click="true">
                        <template v-slot:activator="{ on, attrs }">
                          <div
                              class="mb-0 task--title bradius-4"
                              :class="{
                                'hover': isHovered.includes('editableInput-taskStatus'),
                                'empty-p-field-taskStatus': !status,
                                'fullfield-p-field-taskStatus': status,
                              }"
                              style="position: relative;"
                              @mouseenter="isHoveredOn('editableInput-taskStatus')"
                              @mouseleave="isHoveredOff('editableInput-taskStatus')"
                              @click="startEditing('editableInput-taskStatus')"
                              v-bind="attrs"
                              v-on="on"
                            >
                          <v-chip
                            class="ma-0 pmdashboard__task--content-left--chip"
                            :color="statusColor"
                            label
                          >
                            {{ status }}
                          </v-chip>
                        </div>
                        </template>

                        <!-- Dropdown Menu with Subheaders -->
                        <v-list>
                          <!-- NOT STARTED -->
                          <v-subheader>NOT STARTED</v-subheader>
                          <v-list-item
                            v-for="item in statusOptions['notStarted']"
                            :key="item.text"
                            @click="updateStatus(item)"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-icon left :color="item.color">{{ item.icon }}</v-icon>
                                {{ item.text }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <!-- IN PROGRESS -->
                          <v-subheader>IN PROGRESS</v-subheader>
                          <v-list-item
                            v-for="item in statusOptions['inProgress']"
                            :key="item.text"
                            @click="updateStatus(item)"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-icon left :color="item.color">{{ item.icon }}</v-icon>
                                {{ item.text }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <!-- BLOCKED -->
                          <v-subheader>BLOCKED</v-subheader>
                          <v-list-item
                            v-for="item in statusOptions['blocked']"
                            :key="item.text"
                            @click="updateStatus(item)"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-icon left :color="item.color">{{ item.icon }}</v-icon>
                                {{ item.text }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <!-- FINISHED -->
                          <v-subheader>FINISHED</v-subheader>
                          <v-list-item
                            v-for="item in statusOptions['finished']"
                            :key="item.text"
                            @click="updateStatus(item)"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-icon left :color="item.color">{{ item.icon }}</v-icon>
                                {{ item.text }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 my-1 row-min-height pr-5">
                    <v-col cols="5" class="d-flex align-center pa-0 fixed-width-col">
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_book--error'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        v-if="publishIntent && relatedProject?.name === 'Empty'"
                        />
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_book'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        v-else
                        />
                      <p class="pmdashboard__task--content-left--text mb-0"
                        :class="{ 'error-color': publishIntent && relatedProject?.name === 'Empty', 
                          'shake': publishIntent && relatedProject?.name === 'Empty' }">Project</p>
                    </v-col>
                    <v-col class="pa-0 bradius-4 w-100" :class="{
                        'hover': isHovered.includes('editableInput-relatedProject'),
                        'empty-p-field': relatedProject?.name === 'Empty',
                        'fullfield-p-field': relatedProject?.name !== 'Empty'
                      }"
                      @mouseover="isHoveredOn('editableInput-relatedProject')"
                      @mouseleave="isHoveredOff('editableInput-relatedProject')">
                      <div class="pmdashboard__task--content-left--text mb-0 d-flex align-center">
                        <p
                          v-if="relatedProject?.name === 'Empty' && !relatedProjectMenu"
                          class="mb-0 bradius-4 w-100"
                          id="editableInput-relatedProject"
                          :class="{
                            'hover': isHovered.includes('editableInput-relatedProject'),
                            'empty-p-field': relatedProject?.name === 'Empty',
                            'fullfield-p-field': relatedProject?.name !== 'Empty'
                          }"
                          @mouseover="isHoveredOn('editableInput-relatedProject')"
                          @mouseleave="isHoveredOff('editableInput-relatedProject')"
                          @click="relatedProjectMenu = !relatedProjectMenu"
                        >
                          Empty
                        </p>
                        <div class="pmdashboard__task--content-left--text mb-0 pr-2 d-flex align-center additional-hover p-relative"
                          v-if="!relatedProjectMenu && relatedProject?.name !== 'Empty'"
                          @click="relaunchRelatedProject">
                          <div class="user-status-name">
                            <p class="user-status-name-text mb-0 ml-2" id="editableInput-relatedProject">{{ relatedProject?.name }}</p>
                            <span
                              v-if="relatedProject?.name !== 'Empty' && isHovered.includes('editableInput-relatedProject')"
                              @click="clearRelatedProject()"
                              class="pmdashboard__clear pmdashboard__clear--employer"
                            >
                              <pmdashboard_table_icons
                                :icon="'pmdashboard_clear_text_field'"
                                class="pmdashboard__clear-text d-block"
                              />
                            </span>
                          </div>
                        </div>
                        <v-autocomplete
                          v-show="relatedProjectMenu && relatedProject?.name === 'Empty'"
                          :items="projectsList"
                          ref="relatedProjectAutocomplete" 
                          item-value="id"
                          item-text="name"
                          return-object
                          hide-no-data
                          hide-details
                          id="editableInput-relatedProject"
                          class="mt-0 pt-0 empty-p-field"
                          autofocus
                          :menu-props="{ openOnFocus: true, closeOnContentClick: true }"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item class="additional-hover">
                              <v-list-item-content @click="handleRelatedProjectUpdate(item)">
                                <v-list-item-title>
                                  {{ item?.name }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 my-1 row-min-height pr-5">
                    <v-col cols="5" class="d-flex align-center pa-0 fixed-width-col">
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_account--error'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        v-if="publishIntent && clientRequester.user.firstName === 'Empty'"
                        />
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_account'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        v-else
                        />
                      <p class="pmdashboard__task--content-left--text mb-0"
                        :class="{ 'error-color': publishIntent && clientRequester.user.firstName === 'Empty',
                          'shake': publishIntent && clientRequester.user.firstName === 'Empty'
                        }">
                        Client Requester
                      </p>
                    </v-col>
                    <v-col class="pa-0 bradius-4 w-100" :class="{
                        'hover': isHovered.includes('editableInput-clientRequester'),
                        'empty-p-field': clientRequester.user.firstName === 'Empty',
                        'fullfield-p-field': clientRequester.user.firstName !== 'Empty'
                      }"
                      @mouseover="isHoveredOn('editableInput-clientRequester')"
                      @mouseleave="isHoveredOff('editableInput-clientRequester')">
                      <div class="pmdashboard__task--content-left--text mb-0 d-flex align-center">
                        <p
                          v-if="clientRequester.user.firstName === 'Empty' && !clientRequesterMenu"
                          class="mb-0 bradius-4 w-100"
                          id="editableInput-clientRequester"
                          :class="{
                            'hover': isHovered.includes('editableInput-clientRequester'),
                            'empty-p-field': clientRequester.user.firstName === 'Empty',
                            'fullfield-p-field': clientRequester.user.firstName !== 'Empty'
                          }"
                          @mouseover="isHoveredOn('editableInput-clientRequester')"
                          @mouseleave="isHoveredOff('editableInput-clientRequester')"
                          @click="clientRequesterMenu = !clientRequesterMenu"
                        >
                          Empty (select project first)
                        </p>
                        <div class="pmdashboard__task--content-left--text mb-0 pr-2 d-flex align-center additional-hover p-relative"
                          v-if="!clientRequesterMenu && clientRequester.user.firstName !== 'Empty'"
                          @click="relaunchClientRequester">
                          <div class="user-status-name">
                            <p class="user-status-name-text mb-0 ml-2" id="editableInput-clientRequester">{{ clientRequester.user?.firstName + " " + clientRequester.user?.lastName }}</p>
                            <span
                              v-if="clientRequester.user.firstName !== 'Empty' && isHovered.includes('editableInput-clientRequester')"
                              @click="clearClientRequester()"
                              class="pmdashboard__clear pmdashboard__clear--employer"
                            >
                              <pmdashboard_table_icons
                                :icon="'pmdashboard_clear_text_field'"
                                class="pmdashboard__clear-text d-block"
                              />
                            </span>
                          </div>
                        </div>
                        <v-autocomplete
                          v-show="clientRequesterMenu && clientRequester.user.firstName === 'Empty'"
                          :items="clientRequesterList"
                          ref="clientRequesterAutocomplete" 
                          item-value="id"
                          item-text="name"
                          return-object
                          hide-no-data
                          hide-details
                          id="editableInput-clientRequester"
                          class="mt-0 pt-0 empty-p-field"
                          autofocus
                          :menu-props="{ openOnFocus: true, closeOnContentClick: true }"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item class="additional-hover">
                              <v-list-item-content @click="handleClientRequesterUpdate(item)">
                                <v-list-item-title>
                                  {{ item.user.firstName + " " + item.user.lastName }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 my-1 row-min-height pr-5">
                    <v-col cols="5" class="d-flex align-center pa-0 fixed-width-col">
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_fileLocation--error'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        v-if="publishIntent && !fileLocation"
                        />
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_fileLocation'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        v-else
                        />
                      <p class="pmdashboard__task--content-left--text mb-0"
                        :class="{ 'error-color': publishIntent && !fileLocation,
                          'shake': publishIntent && !fileLocation
                        }">
                        File Location
                      </p>
                    </v-col>
                    <v-col class="pa-0 bradius-4 w-100" :class="{
                        'hover': isHovered.includes('editableInput-fileLocation'),
                        'empty-p-field': !fileLocation,
                        'fullfield-p-field': fileLocation
                      }"
                      @mouseover="isHoveredOn('editableInput-fileLocation')"
                      @mouseleave="isHoveredOff('editableInput-fileLocation')">
                      <div class="pmdashboard__task--content-left--text mb-0 d-flex align-center w-100"
                        @click="relaunchFileLocation">
                        <p
                          v-if="!fileLocation && !fileLocationMenu"
                          class="mb-0 bradius-4 w-100"
                          id="editableInput-fileLocation"
                          :class="{
                            'hover': isHovered.includes('editableInput-fileLocation'),
                            'empty-p-field': !fileLocation,
                            'fullfield-p-field': fileLocation
                          }"
                          @mouseover="isHoveredOn('editableInput-fileLocation')"
                          @mouseleave="isHoveredOff('editableInput-fileLocation')"
                          @click="fileLocationMenu = !fileLocationMenu"
                        >
                          {{ fileLocation ? fileLocation.label : "Empty" }}
                        </p>
                        <div class="pmdashboard__task--content-left--text mb-0 pr-2 d-flex align-center additional-hover p-relative"
                          v-if="!fileLocationMenu && fileLocation"
                          @click="relaunchFileLocation">
                          <div class="user-status-name">
                            <p class="user-status-name-text mb-0 ml-2" id="editableInput-fileLocation">
                              <a target="_blank" :href="fileLocationOptions.find(opt => opt.label === fileLocation)?.link">{{ fileLocationOptions.find(opt => opt.label === fileLocation)?.label }}</a>
                            </p>
                            <span
                              v-if="fileLocation && isHovered.includes('editableInput-fileLocation')"
                              @click="clearFileLocation()"
                              class="pmdashboard__clear pmdashboard__clear--employer"
                            >
                              <pmdashboard_table_icons
                                :icon="'pmdashboard_clear_text_field'"
                                class="pmdashboard__clear-text d-block"
                              />
                            </span>
                          </div>
                        </div>
                        <v-autocomplete
                          v-show="fileLocationMenu && !fileLocation"
                          :items="fileLocationOptions"
                          ref="fileLocationAutocomplete"
                          item-text="label"
                          return-object
                          hide-no-data
                          hide-details
                          id="editableInput-fileLocation"
                          class="mt-0 pt-0 empty-p-field"
                          autofocus
                          :menu-props="{ openOnFocus: true, closeOnContentClick: true }"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item class="additional-hover">
                              <v-list-item-content @click="handleFileLocationUpdate(item)">
                                <v-list-item-title>
                                  {{ item?.label }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 mb-2 d-flex align-center">
                    <div class="pmdashboard__task--delimetr-horizontal"></div>
                  </v-row>
                  <v-row class="pa-0 my-1 row-min-height pr-5">
                    <div class="pmdashboard__task--content-left--dropdown" 
                      style="width: 158px; margin-right: 5px;">
                      <div class="dropdown-wrapper" :class="{'background-color': toggleTimeValue === 'MyTime'}">
                        <span class="dropdown-text" @click="toggleTimeValue = 'MyTime'">{{ myTime.label }}</span>
                        <button class="dropdown-arrow" id="myTimePicker" @click="toggleMyTimeDropdown"></button>
                        <ul v-if="dropdownMyTimeOpen" class="dropdown-menu">
                          <li v-for="option in timeZoneOptions" 
                              :key="option.value"
                              @click="selectMyOption(option)">
                            {{ option.label }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="pmdashboard__task--content-left--dropdown" style="width: 168px;">
                      <div class="dropdown-wrapper" :class="{'background-color': toggleTimeValue === 'ClientTime'}">
                        <span class="dropdown-text" @click="toggleTimeValue = 'ClientTime'">{{ clientTime.label }}</span>
                        <button class="dropdown-arrow" id="clientTimePicker" @click="toggleClentDropdown"></button>
                        <ul v-if="dropdownClientTimeOpen" class="dropdown-menu">
                          <li v-for="option in clientTimeZoneOptions" 
                              :key="option.value" 
                              @click="selectClientOption(option)">
                            {{ option.label }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </v-row>
                  <v-row class="pa-0 my-1 row-min-height pr-5">
                    <v-col cols="5" class="d-flex align-center pa-0 fixed-width-col">
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_request_date--error'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        v-if="publishIntent && !taskRequestDate && !taskRequestTime"
                        />
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_request_date'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        v-else
                        />
                      <p class="pmdashboard__task--content-left--text mb-0"
                        :class="{ 'error-color': publishIntent && !taskRequestDate && !taskRequestTime,
                          'shake': publishIntent && !taskRequestDate && !taskRequestTime
                        }">
                        Request Date
                      </p>
                    </v-col>
                    <v-col class="pa-0 w-100">
                      <div class="pmdashboard__task--content-left--text mb-0">
                        <v-menu
                          v-model="requestDateMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              class="mb-0 bradius-4"
                              :class="{
                                'hover': isHovered.includes('editableInput-requestDate'),
                                'empty-p-field': !(taskRequestDate && taskRequestTime),
                                'fullfield-p-field-requestDate': (taskRequestDate && taskRequestTime),
                              }"
                              @mouseover="isHoveredOn('editableInput-requestDate')"
                              @mouseout="isHoveredOff('editableInput-requestDate')"
                              @click="requestDateMenu = true"
                            >
                              {{ taskRequestDate && taskRequestTime ? formattedRequestDateTime : 'Empty' }}
                            </p>
                          </template>
                          
                          <v-card>
                            <v-date-picker
                              v-if="!showTimePicker"
                              v-model="taskRequestDate"
                              @input="onRequestDateSelected"
                            ></v-date-picker>
                            
                            <v-time-picker
                              v-if="showTimePicker"
                              v-model="taskRequestTime"
                              full-width
                              style="width: 100%;min-width: 300px"
                              @change="onRequestTimeSelected"
                            ></v-time-picker>
                          </v-card>
                          
                        </v-menu>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 my-1 row-min-height pr-5">
                    <v-col cols="5" class="d-flex align-center pa-0 fixed-width-col">
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_deliveryDate--error'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        v-if="publishIntent && !taskDeliveryDate && !taskDeliveryTime"
                        />
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_deliveryDate'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        v-else
                        />
                      <p class="pmdashboard__task--content-left--text mb-0"
                        :class="{ 'error-color': publishIntent && !taskDeliveryDate && !taskDeliveryTime,
                          'shake': publishIntent && !taskDeliveryDate && !taskDeliveryTime
                        }">
                        Delivery Date
                      </p>
                    </v-col>
                    <v-col class="pa-0 w-100">
                      <div class="pmdashboard__task--content-left--text mb-0">
                        <v-menu
                          v-model="deliveryDateMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              class="mb-0 bradius-4"
                              :class="{
                                'hover': isHovered.includes('editableInput-deliveryDate'),
                                'empty-p-field': !(taskDeliveryDate && taskDeliveryTime),
                                'fullfield-p-field-deliveryDate': (taskDeliveryDate && taskDeliveryTime),
                              }"
                              @mouseover="isHoveredOn('editableInput-deliveryDate')"
                              @mouseout="isHoveredOff('editableInput-deliveryDate')"
                              @click="deliveryDateMenu = true"
                            >
                              {{ taskDeliveryDate && taskDeliveryTime ? formattedDeliveryDateTime : 'Empty' }}
                            </p>
                          </template>
                          
                          <v-card>
                            <v-date-picker
                              v-if="!showDeliveryTimePicker"
                              v-model="taskDeliveryDate"
                              @input="onDeliveryDateSelected"
                            ></v-date-picker>
                            
                            <v-time-picker
                              v-if="showDeliveryTimePicker"
                              v-model="taskDeliveryTime"
                              full-width
                              style="width: 100%;min-width: 300px"
                              @change="onDeliveryTimeSelected"
                            ></v-time-picker>
                          </v-card>
                        </v-menu>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div 
                  :class="{'pt-0': windowWidth <= 926}"
                  class="pmdashboard__task--content-right"
                  :style="windowWidth > 926 ? { 
                      width: 'calc(50% - 7.5px)'
                    } : { 
                      width: '100%'
                    }"
                  >
                  <v-row class="pa-0 my-1 row-min-height">
                    <v-col cols="5" class="d-flex align-center pa-0 fixed-width-col">
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_priority_flag'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        />
                      <p class="pmdashboard__task--content-left--text mb-0">Priority 
                        <span style="font-size: 14px; font-weight: 300; font-style: italic">(opt.)</span>
                      </p>
                    </v-col>
                    <v-col class="pa-0 w-100">
                      <div class="pmdashboard__task--content-left--text mb-0 pr-2">
                        <v-menu
                          v-model="priorityMenu"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                        >
                          <!-- Trigger for the menu -->
                          <template v-slot:activator="{ on, attrs }">
                            <div 
                              class="mb-0 bradius-4 d-flex align-center p-relative w-100"
                              :class="{
                                'hover': isHovered.includes('editableInput-priority'),
                                'empty-p-field': taskPriority === 'Empty',
                                'fullfield-p-field': taskPriority !== 'Empty'
                              }"
                              @mouseover="isHoveredOn('editableInput-priority')"
                              @mouseleave="isHoveredOff('editableInput-priority')">
                              <p class="mb-0 w-90" 
                                v-bind="attrs"
                                v-on="on"
                                @click="priorityMenu = !priorityMenu">
                                <v-icon v-if="taskPriority === 'High'" left color="#B13A41" style="width:12px; height: 8px;">mdi-menu-up</v-icon>
                                <v-icon v-else-if="taskPriority === 'Medium'" 
                                  left
                                  class="custom-icon"
                                  color="#CF940A" 
                                  style="width:8px; height: 8px; margin-right: 10px; margin-left: 2px;">
                                  mdi-drag-horizontal-variant
                                </v-icon>
                                <v-icon v-else-if="taskPriority === 'Low'" left color="#4466FF" style="width:12px; height: 8px;">mdi-menu-down</v-icon>
                                {{ taskPriority }}
                              </p>
                              <span
                                v-if="taskPriority !== 'Empty' && isHovered.includes('editableInput-priority')"
                                @click="taskPriority = 'Empty'"
                                class="pmdashboard__clear pmdashboard__clear--employer"
                              >
                                <pmdashboard_table_icons
                                  :icon="'pmdashboard_clear_text_field'"
                                  class="pmdashboard__clear-text d-block"
                                />
                              </span>
                            </div>
                          </template>
                          <v-list style="max-height: 300px; overflow-y: auto;">
                            <v-list-item @click="handlePriorityUpdate('High')">
                              <v-list-item-title>
                                <v-icon left color="#B13A41" style="width:12px; height: 8px;">mdi-menu-up</v-icon>
                                High
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="handlePriorityUpdate('Medium')">
                              <v-list-item-title>
                                <v-icon
                                  left
                                  class="custom-icon"
                                  color="#CF940A" 
                                  style="width:8px; height: 8px; margin-right: 14px; margin-left: -2px;">
                                  mdi-drag-horizontal-variant
                                </v-icon>
                                Medium
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="handlePriorityUpdate('Low')">
                              <v-list-item-title>
                                <v-icon left color="#4466FF" style="width:12px; height: 8px;">mdi-menu-down</v-icon>
                                Low
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 my-1 row-min-height">
                    <v-col cols="5" class="d-flex align-center pa-0 fixed-width-col">
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_account'"
                        class="pmdashboard__task--content-left--icon mr-3"
                      />
                      <p class="pmdashboard__task--content-left--text mb-0">Initiate</p>
                    </v-col>
                    <v-col class="pa-0 w-100">
                      <div class="pmdashboard__task--content-left--text mb-0 pr-2">
                        <p
                          v-if="!initiatorMenu && !initiateUser"
                          class="mb-0 bradius-4 w-100"
                          :class="{
                            'hover': isHovered.includes('editableInput-initiateUser'),
                            'empty-p-field': !initiateUser?.name,
                            'fullfield-p-field': initiateUser?.name
                          }"
                          @mouseover="isHoveredOn('editableInput-initiateUser')"
                          @mouseout="isHoveredOff('editableInput-initiateUser')"
                        >
                          Empty
                        </p>
                        <v-menu
                          v-else
                          v-model="initiatorMenu"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div class="pmdashboard__task--content-left--text mb-0 pr-2 d-flex align-center">
                              <div class="user-status-chip">
                                <v-chip
                                  class="mr-2 chip-width"
                                  color="#656F7D"
                                  text-color="white"
                                  style="font-size: 16px; font-weight: 400; font-family: Roboto !important;"
                                >
                                  <span class="user-status-chip--text" style="margin-bottom: 1px;">Done</span>
                                  <v-icon style="font-size: 18px; font-weight: 300; margin-top: 2px; margin-left: 2px;" right>
                                    mdi-check
                                  </v-icon>
                                </v-chip>
                              </div>
                              <div class="user-status-name">
                                <p class="user-status-name-initials mb-0"
                                  :style="{ 'background-color': getColorForInitials(initiateUser?.initials, initiateUser?.name) }">
                                  {{ initiateUser?.initials }}
                                </p>
                                <p class="user-status-name-text mb-0 ml-2">{{ initiateUser?.name }}</p>
                              </div>
                            </div>
                          </template>
                          <v-list style="max-height: 300px; overflow-y: auto;">
                            <v-list-item v-for="project in projectsList" :key="project.name"
                              @click="handleRelatedProjectUpdate(project)">
                              <v-list-item-title>{{ project.name }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 my-1 row-min-height">
                    <v-col cols="5" class="d-flex align-center pa-0 fixed-width-col">
                      <pmdashboard_table_icons
                        v-if="publishIntent && !assignUser"
                        :icon="'pmdashboard_account--error'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        />
                      <pmdashboard_table_icons
                        v-else
                        :icon="'pmdashboard_account'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        />
                      <p class="pmdashboard__task--content-left--text mb-0"
                        :class="{ 'error-color': publishIntent && !assignUser, 
                          'shake': publishIntent && !assignUser }">Assign</p>
                    </v-col>
                    <v-col class="pa-0 bradius-4 w-100" :class="{
                        'hover': isHovered.includes('editableInput-assignUser'),
                        'empty-p-field': !assignUser?.name,
                        'fullfield-p-field': assignUser?.name
                      }"
                      @mouseover="isHoveredOn('editableInput-assignUser')"
                      @mouseleave="isHoveredOff('editableInput-assignUser')">
                      <div class="pmdashboard__task--content-left--text mb-0 d-flex align-center">
                        <div class="user-status-chip" v-if="assignUser">
                          <v-chip
                            v-if="assignStatus === 'Done'"
                            class="mr-2 chip-width"
                            color="#656F7D"
                            text-color="white"
                            id="editableInput-assignUser"
                            style="font-size: 16px; font-weight: 400; font-family: Roboto !important;"
                          >
                            <span class="user-status-chip--text" style="margin-bottom: 1px;">Done</span>
                            <v-icon style="font-size: 18px; font-weight: 300; margin-top: 2px; margin-left: 2px;" right>
                              mdi-check
                            </v-icon>
                          </v-chip>
                          <v-chip
                            v-else
                            class="custom-chip mr-2 chip-width"
                            text-color="#656F7D"
                            id="editableInput-assignUser"
                            style="font-size: 16px; font-weight: 400; font-family: Roboto !important;"
                          >
                            <span class="user-status-chip--text"
                              style="margin-bottom: 1px; font-weight: 400 !important;">
                              Pending
                            </span>
                          </v-chip>
                        </div>
                        <p
                          v-if="!assignerMenu && !assignUser"
                          class="mb-0 bradius-4 w-100"
                          id="editableInput-assignUser"
                          :class="{
                            'hover': isHovered.includes('editableInput-assignUser'),
                            'empty-p-field': !assignUser?.name,
                            'fullfield-p-field': assignUser?.name
                          }"
                          @mouseover="isHoveredOn('editableInput-assignUser')"
                          @mouseleave="isHoveredOff('editableInput-assignUser')"
                          @click="assignerMenu = !assignerMenu"
                        >
                          Empty
                        </p>
                        <div class="pmdashboard__task--content-left--text mb-0 pr-2 d-flex align-center additional-hover p-relative"
                          v-if="!assignerMenu && assignUser"
                          @click="relaunchAssignerMenu">
                          <div class="user-status-name">
                            <p class="user-status-name-initials mb-0"
                              id="editableInput-assignUser"
                              :style="{ 'background-color': assignUser?.initials && getColorForInitials(assignUser?.initials, assignUser?.name) }">
                              {{ assignUser?.initials }}
                            </p>
                            <p class="user-status-name-text mb-0 ml-2" id="editableInput-assignUser">{{ assignUser?.name }}</p>
                            <span
                              v-if="assignUser && isHovered.includes('editableInput-assignUser')"
                              @click.prevent="assignUser = null"
                              class="pmdashboard__clear pmdashboard__clear--employer"
                            >
                              <pmdashboard_table_icons
                                :icon="'pmdashboard_clear_text_field'"
                                class="pmdashboard__clear-text d-block"
                              />
                            </span>
                          </div>
                        </div>
                        <v-autocomplete
                          v-show="assignerMenu && !assignUser"
                          v-model="assignUser"
                          :items="initiateUsersList"
                          ref="autocomplete" 
                          item-value="id"
                          item-text="name"
                          return-object
                          @change="handleAssignerUpdate"
                          hide-no-data
                          hide-details
                          id="editableInput-assignUser"
                          class="mt-0 pt-0 empty-p-field"
                          autofocus
                          :menu-props="{ openOnFocus: true, closeOnContentClick: true }"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item class="additional-hover">
                              <v-list-item-content @click="handleAssignerUpdate(item)" class="py-2 d-flex justify-space-between align-center">
                                <v-list-item-title class="d-flex align-center">
                                  <v-chip
                                    class="mr-2 dropdown-initials-chip"
                                    :color="getColorForInitials(item?.initials, item?.name)"
                                    text-color="white"
                                  >
                                    {{ item?.initials }}
                                  </v-chip>
                                  {{ item?.name }}
                                  <v-icon v-if="item?.name === temporaryUserStorage?.name"
                                    class="ml-auto"
                                    style="font-size: 20px;
                                      font-weight: 300;
                                      margin-bottom: 3px;"
                                    right>
                                    mdi-check
                                  </v-icon>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 my-1 row-min-height">
                    <v-col cols="5" class="d-flex align-center pa-0 fixed-width-col">
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_account'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        />
                      <p class="pmdashboard__task--content-left--text mb-0">Follow Up</p>
                    </v-col>
                    <v-col class="pa-0 bradius-4 w-100" :class="{
                        'hover': isHovered.includes('editableInput-followUpUser'),
                        'empty-p-field': !followUpUser?.name,
                        'fullfield-p-field': followUpUser?.name
                      }"
                      @mouseover="isHoveredOn('editableInput-followUpUser')"
                      @mouseleave="isHoveredOff('editableInput-followUpUser')">
                      <div class="pmdashboard__task--content-left--text mb-0 pr-2 d-flex align-center p-relative">
                        <div class="user-status-chip" v-if="followUpUser">
                          <v-chip
                            v-if="followUpStatus === 'Done'"
                            class="mr-2 chip-width"
                            color="#656F7D"
                            text-color="white"
                            id="editableInput-followUpUser"
                            style="font-size: 16px; font-weight: 400; font-family: Roboto !important;"
                          >
                            <span class="user-status-chip--text" style="margin-bottom: 1px;">Done</span>
                            <v-icon style="font-size: 18px; font-weight: 300; margin-top: 2px; margin-left: 2px;" right>
                              mdi-check
                            </v-icon>
                          </v-chip>
                          <v-chip
                            v-else
                            class="custom-chip mr-2 chip-width"
                            text-color="#656F7D"
                            id="editableInput-followUpUser"
                            style="font-size: 16px; font-weight: 400; font-family: Roboto !important;"
                          >
                            <span class="user-status-chip--text"
                              style="margin-bottom: 1px; font-weight: 400 !important;">
                              Pending
                            </span>
                          </v-chip>
                        </div>
                        <p
                          v-if="!followUpMenu && !followUpUser"
                          class="mb-0 bradius-4 w-100"
                          :class="{
                            'hover': isHovered.includes('editableInput-followUpUser'),
                            'empty-p-field': !followUpUser?.name,
                            'fullfield-p-field': followUpUser?.name
                          }"
                          id="editableInput-followUpUser"
                          @mouseover="isHoveredOn('editableInput-followUpUser')"
                          @mouseleave="isHoveredOff('editableInput-followUpUser')"
                          @click="followUpMenu = !followUpMenu"
                        >
                          Empty
                        </p>
                        <div class="pmdashboard__task--content-left--text mb-0 pr-2 d-flex align-center additional-hover"
                          v-if="!followUpMenu && followUpUser"
                          @click="relaunchFollowUpMenu">
                          <div class="user-status-name">
                            <p class="user-status-name-initials mb-0"
                              id="editableInput-followUpUser"
                              :style="{ 'background-color': followUpUser?.initials && getColorForInitials(followUpUser?.initials, followUpUser.name) }">
                              {{ followUpUser?.initials }}
                            </p>
                            <p class="user-status-name-text mb-0 ml-2" id="editableInput-followUpUser">{{ followUpUser.name }}</p>
                            <span
                              v-if="followUpUser && isHovered.includes('editableInput-followUpUser')"
                              @click.stop.prevent="followUpUser = null"
                              class="pmdashboard__clear pmdashboard__clear--employer"
                            >
                              <pmdashboard_table_icons
                                :icon="'pmdashboard_clear_text_field'"
                                class="pmdashboard__clear-text d-block"
                              />
                            </span>
                          </div>
                        </div>
                        <v-autocomplete
                          v-show="followUpMenu && !followUpUser"
                          v-model="followUpUser"
                          id="editableInput-followUpUser"
                          ref="followUpAutocomplete"
                          :items="initiateUsersList"
                          item-value="id"
                          item-text="name"
                          return-object
                          @change="handleFollowUpUpdate"
                          hide-no-data
                          hide-details
                          class="mt-0 pt-0 empty-p-field"
                          autofocus
                          :menu-props="{ openOnFocus: true, closeOnContentClick: true }"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item class="additional-hover">
                              <v-list-item-content @click="handleFollowUpUpdate(item)" class="py-2 d-flex justify-space-between align-center">
                                <v-list-item-title class="d-flex align-center">
                                  <v-chip
                                    class="mr-2 dropdown-initials-chip"
                                    :color="getColorForInitials(item?.initials, item?.name)"
                                    text-color="white"
                                  >
                                    {{ item?.initials }}
                                  </v-chip>
                                  {{ item?.name }}
                                  <v-icon v-if="item?.name === temporaryUserStorage?.name"
                                    class="ml-auto"
                                    style="font-size: 20px;
                                      font-weight: 300;
                                      margin-bottom: 3px;"
                                    right>
                                    mdi-check
                                  </v-icon>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 mb-3 mt-2 row-min-height">
                    <v-col cols="5" class="d-flex align-center pa-0 fixed-width-col">
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_account'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        />
                      <p class="pmdashboard__task--content-left--text mb-0">Delivery</p>
                    </v-col>
                    <v-col class="pa-0 bradius-4 w-100" :class="{
                        'hover': isHovered.includes('editableInput-deliveryUser'),
                        'empty-p-field': !deliveryUser?.name,
                        'fullfield-p-field': deliveryUser?.name
                      }"
                      @mouseover="isHoveredOn('editableInput-deliveryUser')"
                      @mouseleave="isHoveredOff('editableInput-deliveryUser')">
                      <div class="pmdashboard__task--content-left--text mb-0 d-flex align-center">
                        <div class="user-status-chip" v-if="deliveryUser">
                          <v-chip
                            v-if="deliveryStatus === 'Done'"
                            class="mr-2 chip-width"
                            color="#656F7D"
                            text-color="white"
                            id="editableInput-deliveryUser"
                            style="font-size: 16px; font-weight: 400; font-family: Roboto !important;"
                          >
                            <span class="user-status-chip--text" style="margin-bottom: 1px;">Done</span>
                            <v-icon style="font-size: 18px; font-weight: 300; margin-top: 2px; margin-left: 2px;" right>
                              mdi-check
                            </v-icon>
                          </v-chip>
                          <v-chip
                            v-else
                            class="custom-chip mr-2 chip-width"
                            text-color="#656F7D"
                            id="editableInput-deliveryUser"
                            style="font-size: 16px; font-weight: 400; font-family: Roboto !important;"
                          >
                            <span class="user-status-chip--text"
                              style="margin-bottom: 1px; font-weight: 400 !important;">
                              Pending
                            </span>
                          </v-chip>
                        </div>
                        <p
                          v-if="!deliveryUserMenu && !deliveryUser"
                          class="mb-0 bradius-4 w-100"
                          :class="{
                            'hover': isHovered.includes('editableInput-deliveryUser'),
                            'empty-p-field': !deliveryUser?.name,
                            'fullfield-p-field': deliveryUser?.name
                          }"
                          id="editableInput-deliveryUser"
                          @mouseover="isHoveredOn('editableInput-deliveryUser')"
                          @mouseleave="isHoveredOff('editableInput-deliveryUser')"
                          @click="deliveryUserMenu = !deliveryUserMenu"
                        >
                          Empty
                        </p>
                        <div class="pmdashboard__task--content-left--text mb-0 pr-2 d-flex align-center additional-hover p-relative"
                          v-if="!deliveryUserMenu && deliveryUser"
                          @click="relaunchDeliveryUserMenu">
                          <div class="user-status-name">
                            <p class="user-status-name-initials mb-0"
                              id="editableInput-deliveryUser"
                              :style="{ 'background-color': deliveryUser.initials && getColorForInitials(deliveryUser.initials, deliveryUser.name) }">
                              {{ deliveryUser.initials }}
                            </p>
                            <p class="user-status-name-text mb-0 ml-2" id="editableInput-deliveryUser">{{ deliveryUser.name }}</p>
                            <span
                              v-if="deliveryUser && isHovered.includes('editableInput-deliveryUser')"
                              @click.stop.prevent="deliveryUser = null"
                              class="pmdashboard__clear pmdashboard__clear--employer"
                            >
                              <pmdashboard_table_icons
                                :icon="'pmdashboard_clear_text_field'"
                                class="pmdashboard__clear-text d-block"
                              />
                            </span>
                          </div>
                        </div>
                        <v-autocomplete
                          v-show="deliveryUserMenu && !deliveryUser"
                          v-model="deliveryUser"
                          ref="deliveryAutocomplete"
                          :items="initiateUsersList"
                          item-value="id"
                          item-text="name"
                          return-object
                          id="editableInput-deliveryUser"
                          @change="handleDeliveryUpdate"
                          hide-no-data
                          hide-details
                          class="mt-0 pt-0 empty-p-field"
                          autofocus
                          :menu-props="{ openOnFocus: true, closeOnContentClick: true }"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item class="additional-hover">
                              <v-list-item-content @click="handleDeliveryUpdate(item)" class="py-2 d-flex justify-space-between align-center">
                                <v-list-item-title class="d-flex align-center">
                                  <v-chip
                                    class="mr-2 dropdown-initials-chip"
                                    :color="getColorForInitials(item?.initials, item?.name)"
                                    text-color="white"
                                  >
                                    {{ item?.initials }}
                                  </v-chip>
                                  {{ item?.name }}
                                  <v-icon v-if="item?.name === temporaryUserStorage?.name"
                                    class="ml-auto"
                                    style="font-size: 20px;
                                      font-weight: 300;
                                      margin-bottom: 3px;"
                                    right>
                                    mdi-check
                                  </v-icon>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 mb-2 d-flex align-center">
                    <div class="pmdashboard__task--delimetr-horizontal w-100"></div>
                  </v-row>
                  <v-row class="pa-0 my-1 row-min-height">
                    <v-col cols="5" class="d-flex align-center pa-0 fixed-width-col">
                      <v-icon style="font-size: 24px; font-weight: 300;margin-left: -2px; margin-right: 10px">
                        mdi-cash
                      </v-icon>
                      <p class="pmdashboard__task--content-left--text mb-0"
                        :class="{ 'error-color': publishIntent && !payPeriod,
                          'shake': publishIntent && !payPeriod
                        }">
                        Pay Period
                      </p>
                    </v-col>
                    <v-col class="pa-0 w-100">
                      <div class="pmdashboard__task--content-left--text mb-0 w-100">
                        <v-menu
                          v-model="payPeriodMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              class="mb-0 bradius-4 w-100"
                              :class="{
                                'hover': isHovered.includes('editableInput-payPeriod'),
                                'empty-p-field': !payPeriod,
                                'fullfield-p-field': payPeriod,
                              }"
                              @mouseover="isHoveredOn('editableInput-payPeriod')"
                              @mouseout="isHoveredOff('editableInput-payPeriod')"
                              @click="payPeriodMenu = true"
                            >
                              {{ payPeriod ? formattedPayPeriod(payPeriod) : 'Empty' }}
                            </p>
                          </template>
                          
                          <v-card>
                            <v-date-picker
                              type="month"
                              v-model="payPeriod"
                              @input="onPayPeriodPicker"
                            ></v-date-picker>
                          </v-card>
                          
                        </v-menu>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 my-1 row-min-height">
                    <v-col cols="5" class="d-flex align-center pa-0 fixed-width-col">
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_billed'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        />
                      <p class="pmdashboard__task--content-left--text mb-0">Billed</p>
                    </v-col>
                    <v-col class="pa-0 w-100">
                      <div class="pmdashboard__task--content-left--text mb-0 w-100 file-location-input d-flex align-center bradius-4"
                        :class="{
                          'hover': isHovered.includes('editableInput-billed'),
                          }"
                          @mouseover="isHoveredOn('editableInput-billed')"
                          @mouseleave="isHoveredOff('editableInput-billed')"
                          @click="toggleIsBilled()">
                        <v-checkbox
                          class="mt-0 pt-0 ml-1"
                          hide-details="true"
                          :input-value="isBilled"
                        ></v-checkbox>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 my-1 row-min-height">
                    <v-col cols="5" class="d-flex align-center pa-0 fixed-width-col">
                      <pmdashboard_table_icons
                        :icon="'pmdashboard_checked_billed'"
                        class="pmdashboard__task--content-left--icon mr-3"
                        />
                      <p class="pmdashboard__task--content-left--text mb-0">Checked</p>
                    </v-col>
                    <v-col class="pa-0 w-100">
                      <div class="pmdashboard__task--content-left--text mb-0 w-100 file-location-input d-flex align-center bradius-4"
                          :class="{'hover': isHovered.includes('editableInput-isChecked')}"
                          @mouseover="isHoveredOn('editableInput-isChecked')"
                          @mouseleave="isHoveredOff('editableInput-isChecked')"
                          @click="toggleCheckbox()"
                      >
                          <v-checkbox
                            class="mt-0 pt-0 ml-1 z-9999"
                            hide-details="true"
                            :input-value="isChecked"
                          ></v-checkbox>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-row>
              <v-row class="d-flex align-center pmdashboard__task--title pl-6">
                <p class="task--id assignments-title">Assignments</p>
                <v-btn
                  text
                  small
                  @click.prevent="openInNewTab"
                >
                  <pmdashboard_table_icons
                    :icon="'pmdashboard_apps-add'"
                    class="pmdashboard__task--content-left--icon"
                    />
                </v-btn>
              </v-row>
              <v-row class="pmdashboard__task--content-left--chips d-flex align-center mb-2 pl-5" v-if="assignmentsList.length">
                <v-col v-for="(chip, index) in assignmentsFilters" :key="index" cols="auto" class="pa-1" v-if="chip.value">
                  <v-chip
                    :color="chip.color"
                    text-color="white"
                    class="pmdashboard__task--content-left--chips-item"
                    @click.prevent="assignmentsFilter = chip.label">
                    {{ chip.label }}: {{ chip.value }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row class="pmdashboard__task--content--assignments pl-3">
                <PMDashboardAssignmentsTable
                  v-if="assignmentsList.length"
                  :windowWidth="windowWidth"
                  :itemsList="assignmentsListFiltered"
                  />
                <p class="pl-3 pmdashboard__task--content--assignments-text" v-else>There are no assignments yet</p>
              </v-row>
            </div>
            <div style="width: 100%;" 
              class="animated-section"
              v-show="settingsSection === 'Activity'">
              <v-col
                cols="12"
                class="pmdashboard__task--content-left pa-0" >
                <PMDashboardFormActivity 
                  :key="formActivityUpdate"
                  :itemToEdit="itemToEdit?.id ? itemToEdit : itemToEditNew"
                  :taskId="idNumber"
                  />
              </v-col>
            </div>
            <div v-if="windowWidth <= 1286"
              class="pmdashboard__task--settings">
              <div class="pmdashboard__task--settings--button mt-3"
                @click="settingsSection = 'Details'">
                <div class="pmdashboard__task--settings--button-icon"
                  :style="settingsSection === 'Details' ? { backgroundColor: '#7575754D' } : { backgroundColor: '#FFF' }">
                  <pmdashboard_table_icons
                    :icon="'pmdashboard_details2'"
                    />
                </div>
                <p class="mb-0 settings--text">Details</p>
              </div>
              <div class="pmdashboard__task--settings--button mt-3"
                @click="settingsSection = 'Activity'">
                <div class="pmdashboard__task--settings--button-icon"
                  :style="settingsSection === 'Activity' ? { backgroundColor: '#7575754D' } : { backgroundColor: '#FFF' }">
                  <pmdashboard_table_icons
                    :icon="'pmdashboard_activity'"
                    />
                </div>
                <p class="mb-0 settings--text">Activity</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="windowWidth > 1286"
          :style="windowWidth < 600 ? { width: '100%' } : { width: '420px' }"
          style="border-left: 1px solid #E0E0E0"
          class="pmdashboard__task--content-left pa-0" >
          <PMDashboardFormActivity 
            :key="formActivityUpdate"
            :itemToEdit="itemToEdit?.id ? itemToEdit : itemToEditNew"
            :taskId="idNumber"
            />
        </div>
      </div>
      
    </v-container>
  
    <v-dialog
      max-width="1200"
      v-model="slackFormModal"
      @click:outside="slackFormModal = false"
      @keydown.esc="slackFormModal = false"
    >
      <SlackInquiryForm
        :inquiryItem="inquiryTask"
        :mode="'task'"
        :leadPMSlack="leadPM?.adminDetails?.slackIdFc"
        @close-slack-form="slackFormModal = false"
      />
    </v-dialog>

    <v-dialog
      max-width="500"
      v-model="confirmationModal"
      @click:outside="confirmationModal = false"
      @keydown.esc="confirmationModal = false"
    >
      <div class="pmdashboard__confirmation-modal">
        <p class="pmdashboard__confirmation-modal--title" v-if="!gIsEditing">Discard task?</p>
        <p class="pmdashboard__confirmation-modal--title" v-else>Discard changes?</p>
        <p class="pmdashboard__confirmation-modal--text" v-if="!gIsEditing">The task has not been published yet. Are you sure you want to close the window and discard it? Any unsaved data will be lost.
          To keep the data, please complete all required fields and publish the task.</p>
        <p class="pmdashboard__confirmation-modal--text" v-else>There are unsaved changes. Are you sure you want to close the window and discard them? Any unsaved data will be lost. To keep the data, please complete all required fields and save your changes.</p>
        <div class="pmdashboard__confirmation-modal--buttons d-flex align-center justify-end">
          <v-btn
            text
            @click="confirmationModal = false"
            class="pmdashboard__confirmation-modal--buttons-continue mr-2"
          >
            Continue Editing
          </v-btn>
          <v-btn
            v-if="!gIsEditing"
            depressed
            color="#FF5252"
            @click="discardTask"
            class="pmdashboard__confirmation-modal--buttons-discard"
          >
            Discard Task
          </v-btn>
          <v-btn
            v-else
            depressed
            color="#FF5252"
            @click="discardTask"
            class="pmdashboard__confirmation-modal--buttons-discard"
          >
            Discard Changes
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <div class="pm-form__messages" v-if="alert.status">
      <v-alert
        dense
        text
        :type="alert.type"
      >
        {{ alert.message}}
      </v-alert>
    </div>
  </v-card>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import { newProcessTask, newGetTaskById, processTaskComment } from "@/utils/newDbUtils.js";
import { alphabetColors, managersDesiredColors, ROUTES, fileLocationDropdownOptions } from "@/utils/constants.js";
import SlackInquiryForm from "../ui/SlackInquiryForm.vue";
import PMDashboardAssignmentsTable from "@/components/PMDashboard/PMDashboardAssignmentsTable.vue";
import PMDashboardFormActivity from "@/components/PMDashboard/PMDashboardFormActivity.vue";
import pmdashboard_table_icons from "@/assets/icons/pmdashboard_table_icons.vue";

export default {
  name: "PMDashboardForm",
  components: {
    PMDashboardAssignmentsTable,
    SlackInquiryForm,
    PMDashboardFormActivity,
    pmdashboard_table_icons
  },
  props: {
    mediaTypes: {
      type: Array,
      required: true,
    },
    taskStatuses: {
      type: Array,
      required: true,
    },
    priorities: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
    projectsList: {
      type: Array,
      required: true,
    },
    itemToEdit: {
      type: Object,
      required: false,
    },
    leadPM: {
      type: Object,
      required: true,
    },
    openConfirmationModal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      alert: {
        status: false,
        type: 'success',
        message: '',
      },
      confirmationModal: false,
      createTaskLoader: false,
      myTime: {
        label: `My (EDT-04:00)`,
        value: "-04:00",
      },
      clientTime: {
        label: `Client (EDT-04:00)`,
        value: "-04:00",
      },
      defaultTime: "-04:00",
      ifTimeZoneWasTriggered: false,
      toggleTimeValue: 'ClientTime',
      dropdownClientTimeOpen: false,
      dropdownMyTimeOpen: false,
      text: 'Empty',
      isHovered: [],
      isEditing: [],
      checkbox: false,
      slackFormModal: false,
      inquiryTask: {},
      idNumber: '',
      taskName: '',
      status: 'Requested',
      statusOptions: {
        notStarted: [
          { text: 'Requested', color: '#7BC4C480', icon: 'mdi-circle' },
          { text: 'Confirmed', color: '#7BC4C4', icon: 'mdi-circle' },
        ],
        inProgress: [
          { text: 'Assigned', color: '#8CA9C3', icon: 'mdi-circle' },
          { text: 'In Progress', color: '#3F599B', icon: 'mdi-circle' },
        ],
        blocked: [
          { text: 'On Hold', color: '#EBCF6D', icon: 'mdi-circle' },
        ],
        finished: [
          { text: 'Delivered', color: '#70D9A4', icon: 'mdi-circle' },
          { text: 'Cancelled', color: '#FFA1A6', icon: 'mdi-circle' },
        ],
      },
      relatedProjectMenu: false,
      relatedProject: {
        name: "Empty"
      },
      clientRequesterMenu: false,
      clientRequester: {
        user: {
          firstName: "Empty",
          lastName: "",
        }
      },
      fileLocationOptions: fileLocationDropdownOptions,
      fileLocationMenu: false,
      fileLocation: null,
      priorityMenu: false,
      taskPriority: "Empty",
      requestDateMenu: false,
      showTimePicker: false,
      taskRequestDate: null,
      taskRequestTime: null,
      deliveryDateMenu: false,
      taskDeliveryDate: null,
      taskDeliveryTime: null,
      showDeliveryTimePicker: false,
      temporaryUserStorage: null,
      initiatorMenu: false,
      initiateUser: null,
      initiateStatus: null,
      assignerMenu: false,
      assignUser: null,
      assignStatus: null,
      followUpMenu: false,
      followUpUser: null,
      followUpStatus: null,
      deliveryUserMenu: false,
      deliveryUser: null,
      deliverStatus: null,
      payPeriod: null,
      payPeriodMenu: false,
      isBilled: false,
      isChecked: false,
      publishIntent: false,
      currentDRI: null,
      assignmentsList: [],
      assignmentsFilter: '',
      formActivityUpdate: 0,
      responseClone: null,
      deliveryStatus: null,
      createdDate: null,
      updatedDate: null,
      itemToEditNew: {},
      minHeight: 190,
      minWindowHeight: 330,
      windowWidth: window.innerWidth,
      settingsSection: 'Details',
      timeZones: [
        "PST-08:00",
        "PDT-07:00",
        "CST-06:00",
        "EST-05:00",
        "EDT-04:00",
        "GMT+00:00",
        "CET+01:00",
        "CEST+02:00",
        "MSK+03:00",
        "GST+04:00",
        "HKT+08:00",
        "KST+09:00",
      ]
    };
  },
  watch: {
    myTime: {
      handler() {
        localStorage.setItem("myTimeTaskForm", JSON.stringify(this.myTime));
      },
      immediate: false,
    },
    openConfirmationModal: {
      handler() {
        this.closeForm();
      }
    },
    toggleTimeValue() {
      this.ifTimeZoneWasTriggered = true;
    },
  },
  computed: {
    ...mapGetters("staff", ["staffList"]),
    ...mapGetters("pmDashboard", ["gIsEditing"]),
    ...mapGetters("auth", ["user"]),
    dynamicHeightStyle() {
      const windowHeight = window.innerHeight;
      const calculatedHeight = Math.max(this.minHeight, windowHeight - (this.minWindowHeight - this.minHeight));
      return {
        height: `${calculatedHeight}px`,
      };
    },
    assignmentsFilters() {
      const items = [
        { label: 'Requested', value: 0, color: '#7BC4C4' },
        { label: 'Assigned', value: 0, color: '#8CA9C3' },
        { label: 'Primary Complete', value: 0, color: '#9FB8F8' },
        { label: 'Secondary Complete', value: 0, color: '#567DDF' },
        { label: 'QC Complete', value: 0, color: '#3F599B' },
        { label: 'Pending Hours', value: 0, color: '#EBCF6D' },
        { label: 'Reviewing Hours', value: 0, color: '#EBCF6D' },
        { label: 'On Hold', value: 0, color: '#EBCF6D' },
        { label: 'Ready for Delivery', value: 0, color: '#C5A8EC' },
        { label: 'Delivered', value: 0, color: '#70D9A4' },
        { label: 'Cancelled', value: 0, color: '#FFA1A6' },
      ];
      this.assignmentsList.forEach((el) => {
        items.find((item) => item.label === el.assignmentStatus).value++;
      })
      return items;
    },
    checkIfFieldUpdated() {
      let updated = false;
      if (this.responseClone) {
        if (
          this.payPeriod !== this.responseClone.payPeriod ||
          this.taskName !== this.responseClone.taskName ||
          this.idNumber !== this.responseClone.taskId ||
          this.fileLocation !== this.responseClone.fileLocation ||
          this.relatedProject.id !== this.responseClone.projectId ||
          this.clientRequester.user.id !== this.responseClone.clientRequesterId ||
          this.status !== this.responseClone.taskStatus ||
          (this.taskPriority && this.taskPriority !== "Empty" && this.taskPriority !== this.responseClone.priority) ||
          (this.initiateUser ? this.initiateUser.id !== this.responseClone?.initiatedBy?.id : false) ||
          (this.assignUser ? this.assignUser.id !== this.responseClone?.assignBy?.id : false) ||
          (this.responseClone?.assignBy?.id && !this.assignUser) ||
          (this.followUpUser ? this.followUpUser.id !== this.responseClone?.followUpBy?.id : false) ||
          (this.responseClone?.followUpBy?.id && !this.followUpUser) ||
          (this.deliveryUser ? this.deliveryUser.id !== this.responseClone?.deliverBy?.id : false) ||
          (this.responseClone?.deliverBy?.id && !this.deliveryUser) ||
          this.isBilled !== this.responseClone.isBilled ||
          this.isChecked !== this.responseClone.isChecked ||
          (this.responseClone.requestDate ? this.formatDateFromDB(this.responseClone.requestDate) !== this.taskRequestDate : false) ||
          (this.responseClone.deliveryDate ? this.formatDateFromDB(this.responseClone.deliveryDate) !== this.taskDeliveryDate : false) ||
          (this.responseClone.requestTime ? this.formatTimeFromDB(this.responseClone.requestTime) !== this.taskRequestTime : false) ||
          (this.responseClone.deliveryTime ? this.formatTimeFromDB(this.responseClone.deliveryTime) !== this.taskDeliveryTime : false)
        ) {
          updated = true;
        };
      }
      return updated;
    },
    assignmentsListFiltered() {
      if (!this.assignmentsFilter) return this.assignmentsList
      return this.assignmentsList.filter((el) => el.assignmentStatus === this.assignmentsFilter);
    },
    checkRequiredFields() {
      return (
        this.taskRequestDate &&
        this.taskRequestTime &&
        this.taskDeliveryDate &&
        this.taskDeliveryTime &&
        this.status &&
        this.relatedProject.name != "Empty" &&
        this.clientRequester.user.firstName != "Empty" &&
        this.fileLocation &&
        this.assignUser.id
      );
    },
    checkFieldsAreEmpty() {
      return (
        this.taskName ||
        this.taskDeliveryDate ||
        this.taskDeliveryTime ||
        this.relatedProject.name != "Empty" ||
        this.clientRequester.user.firstName != "Empty" ||
        this.fileLocation
      );
    },
    initiateUsersList() {
      return this.staffList.filter((el) => el.user.userRole === "MANAGER" || el.user.userRole === "ADMIN").map((el) => ({
        name: `${el.user.firstName} ${el.user.lastName}`,
        initials: `${el.user.firstName[0]}${el.user.lastName[0]}`,
        id: el.user.id,
      }))
    },
    formattedRequestDateTime() {
      if (this.taskRequestDate && this.taskRequestTime) {
        const date = new Date(`${this.taskRequestDate}T${this.taskRequestTime}`);
        const [defaultHours, defaultMinutes] = this.defaultTime.split(':').map(Number);
        const defaultOffset = 60 * defaultHours;
        let timeOffset;
        if (!this.ifTimeZoneWasTriggered) return this.formatDateTime(date);
        if (this.toggleTimeValue === 'MyTime') {
          timeOffset = this.myTime.value;
        } else if (this.toggleTimeValue === 'ClientTime') {
          timeOffset = this.clientTime.value;
        }
        const [hoursOffset, minutesOffset] = timeOffset.split(':').map(Number);
        const selectedOffset = hoursOffset * 60 + minutesOffset;
        const offsetDifference = selectedOffset - defaultOffset;
        date.setUTCMinutes(date.getUTCMinutes() + offsetDifference);
        return this.formatDateTime(date);
      }

      return 'Empty';
    },
    formattedDeliveryDateTime() {
      if (this.taskDeliveryDate && this.taskDeliveryTime) {
        const date = new Date(`${this.taskDeliveryDate}T${this.taskDeliveryTime}`);
        const [defaultHours, defaultMinutes] = this.defaultTime.split(':').map(Number);
        const defaultOffset = 60 * defaultHours;
        let timeOffset;
        if (!this.ifTimeZoneWasTriggered) return this.formatDateTime(date);
        if (this.toggleTimeValue === 'MyTime') {
          timeOffset = this.myTime.value;
        } else if (this.toggleTimeValue === 'ClientTime') {
          timeOffset = this.clientTime.value;
        }
        const [hoursOffset, minutesOffset] = timeOffset.split(':').map(Number);
        const selectedOffset = hoursOffset * 60 + minutesOffset;
        const offsetDifference = selectedOffset - defaultOffset;
        date.setUTCMinutes(date.getUTCMinutes() + offsetDifference);
        return this.formatDateTime(date);
      }
      return 'Empty';
    },
    timeZoneOptions() {
      const options = [];
      for (let i = 0; i < this.timeZones.length; i++) {
        const offset = this.timeZones[i].slice(-6);
        options.push({
          label: `My (${this.timeZones[i]})`,
          value: offset,
        });
      }
      return options;
    },
    clientTimeZoneOptions() {
      const options = [];
      for (let i = 0; i < this.timeZones.length; i++) {
        const offset = this.timeZones[i].slice(-6);
        options.push({
          label: `Client (${this.timeZones[i]})`,
          value: offset,
        });
      }
      return options;
    },
    statusColor() {
      const allOptions = [
        ...this.statusOptions.notStarted,
        ...this.statusOptions.inProgress,
        ...this.statusOptions.blocked,
        ...this.statusOptions.finished,
      ];
      const statusOption = allOptions.find(
        (option) => option.text === this.status
      );
      return statusOption ? statusOption.color : '#000';
    },
    clientRequesterList() {
      if (this.relatedProject && this.relatedProject.clientRequesters?.length) {
        const clients = this.relatedProject.clientRequesters;
        clients.forEach((el) => {
          el.name = `${el.user.firstName} ${el.user.lastName}`
        })
        return clients.sort((a, b) => (a.user?.firstName > b.user?.firstName ? 1 : -1));
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    ...mapActions("pmDashboard", ["aSetIsEditing"]),
    showAlert(data) {
      this.alert = {
        status: true,
        type: data?.type ? data?.type : 'error',
        message: data?.message ? data?.message : 'Something went wrong'
      }
      setTimeout(() => this.closeAlert(), 4000);
    },
    closeAlert() {
      this.alert.status = false;
    },
    formatDateTime(date) {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC',
      };
      return date.toLocaleString('en-US', options);
    },
    toggleClentDropdown() {
      this.dropdownClientTimeOpen = !this.dropdownClientTimeOpen;
    },
    toggleMyTimeDropdown() {
      this.dropdownMyTimeOpen = !this.dropdownMyTimeOpen;
    },
    selectClientOption(option) {
      this.ifTimeZoneWasTriggered = true;
      this.clientTime = option;
      this.dropdownClientTimeOpen = false;
    },
    selectMyOption(option) {
      this.ifTimeZoneWasTriggered = true;
      this.myTime = option;
      this.dropdownMyTimeOpen = false;
    },
    formatDateTime(date) {
      const options = { day: '2-digit', month: 'short', year: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-GB', options);
      const formattedTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
      return `${formattedDate.replace(/,/g, '')} (${formattedTime})`;
    },
    onPayPeriodPicker() {
      this.payPeriodMenu = false;
    },
    onRequestDateSelected() {
      this.showTimePicker = true;
    },
    formattedPayPeriod(dateString) {
      const [year, month] = dateString.split("-");
      const date = new Date(year, month - 1, 1);

      if (isNaN(date)) {
        console.error('Invalid Date:', dateString);
        return 'Invalid Date';
      }

      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
    },
    onRequestTimeSelected() {
      this.defaultTime = this.toggleTimeValue === 'MyTime' ? this.myTime.value : this.clientTime.value;
      this.ifTimeZoneWasTriggered = false;
      this.stopEditing("requestDate");
    },
    onDeliveryDateSelected() {
      this.showDeliveryTimePicker = true;
    },
    onDeliveryTimeSelected() {
      this.defaultTime = this.toggleTimeValue === 'MyTime' ? this.myTime.value : this.clientTime.value;
      this.ifTimeZoneWasTriggered = false;
      this.stopEditing("deliveryDate");
    },
    startEditing(item) {
      this.isEditing.push(item);
      this.$nextTick(() => {
        this.$refs[item].focus();
      });
    },
    toggleCheckbox() {
      this.isChecked = !this.isChecked;
    },
    toggleIsBilled() {
      this.isBilled = !this.isBilled;
    },
    stopEditing(item) {
      if (
        item != "relatedProject" 
        || item != "clientRequester"
        || item != "deliveryDate"
        || item != "requestDate"
      ) {
        this.isEditing = this.isEditing.filter((i) => i !== item);
        this.isHovered = this.isHovered.filter((i) => i !== item);
      }
    },
    getColorForInitials(initials, name) {
      if (name) {
        const colorItem = managersDesiredColors.find(el => el.name === name);
        if (colorItem) {
          return colorItem.color
        } else return initials && initials[1] && alphabetColors.find((color) => color.character === initials[1])?.color || "#9747FF";
      }
      return initials && initials[1] && alphabetColors.find((color) => color.character === initials[1])?.color || "#9747FF";
    },
    isHoveredOn(item) {
      if (!this.isHovered.includes(item)) {
        this.isHovered.push(item);
      }
    },
    isHoveredOff(item) {
      if (this.isHovered.includes(item)) {
        this.isHovered = this.isHovered.filter((i) => i !== item);
      }
    },
    handleRelatedProjectUpdate(item) {
      this.relatedProject = item;
      this.clientRequester = {
        user: {
          firstName: "Empty",
          lastName: "",
        }
      };
      this.relatedProjectMenu = false;
      this.stopEditing("relatedProject");
    },
    handleClientRequesterUpdate(item) {
      this.clientRequester = item;
      this.stopEditing("clientRequester");
      this.clientRequesterMenu = false;
    },
    handleFileLocationUpdate(item) {
      this.fileLocation = item.label;
      this.stopEditing("fileLocation");
      this.fileLocationMenu = false;
    },
    clearFileLocation() {
      this.fileLocation = null;
    },
    clearClientRequester() {
      this.clientRequester = null;
      this.clientRequester = {
        user: {
          firstName: "Empty",
          lastName: "",
        }
      };
    },
    clearRelatedProject() {
      this.relatedProject = null;
      this.relatedProject = {
        name: 'Empty'
      };
      this.clientRequester = {
        user: {
          firstName: "Empty",
          lastName: "",
        }
      };
    },
    handleAssignerUpdate(item) {
      this.assignUser = item;
      this.assignerMenu = false;
    },
    relaunchRelatedProject() {
      if (this.relatedProject && this.relatedProject.name !== "Empty") {
        this.temporaryUserStorage = _.cloneDeep(this.relatedProject);
      }
      this.relatedProjectMenu = true;
      this.relatedProject = {
        name: "Empty"
      }
    },
    relaunchClientRequester() {
      if (this.clientRequester && this.clientRequester.user.firstName !== "Empty") {
        this.temporaryUserStorage = _.cloneDeep(this.clientRequester);
      }
      this.clientRequesterMenu = true;
      this.clientRequester = {
        user: {
          firstName: "Empty",
          lastName: "",
        }
      }
    },
    relaunchFileLocation() {
      if (this.fileLocation) {
        this.temporaryUserStorage = _.cloneDeep(this.fileLocation);
      }
      this.fileLocation = null;
      this.fileLocationMenu = true;
    },
    relaunchAssignerMenu() {
      if (this.assignUser && this.assignUser.id) {
        this.temporaryUserStorage = _.cloneDeep(this.assignUser);
      }
      this.assignUser = null;
      this.assignerMenu = true;
    },
    relaunchFollowUpMenu() {
      if (this.followUpUser && this.followUpUser.id) {
        this.temporaryUserStorage = _.cloneDeep(this.followUpUser);
      }
      this.followUpUser = null;
      this.followUpMenu = true;
    },
    relaunchDeliveryUserMenu() {
      if (this.deliveryUser && this.deliveryUser.id) {
        this.temporaryUserStorage = _.cloneDeep(this.deliveryUser);
      }
      this.deliveryUser = null;
      this.deliveryUserMenu = true;
    },
    handleDeliveryUpdate(item) {
      this.deliveryUser = item;
      this.deliveryUserMenu = false;
    },
    handleFollowUpUpdate(item) {
      this.followUpUser = item;
      this.followUpMenu = false;
    },
    updateStatus(item) {
      this.status = item.text;
    },
    handlePriorityUpdate(item) {
      this.taskPriority = item;
    },
    taskUpdateMessageGenerator() {
      if (this.gIsEditing) {
        const messages = [];
        if (this.taskName !== this.responseClone.taskName) messages.push("Task name was updated");
        if (this.status !== this.responseClone.taskStatus) messages.push(`Task status was updated to ${this.status}`);
        if (this.idNumber !== this.responseClone?.taskId) messages.push(`Task ID was updated to ${this.idNumber}`);
        if (this.relatedProject?.id !== this.responseClone.projectId) messages.push("Project was changed");
        if (this.clientRequester.user.id !== this.responseClone.clientRequesterId) messages.push(`Client Requester was changed to ${this.clientRequester?.user.firstName} ${this.clientRequester?.user.lastName}`);
        if (this.taskPriority !== this.responseClone.priority) messages.push(`Task priority was updated to ${this.taskPriority}`);
        if (this.fileLocation !== this.responseClone.fileLocation) messages.push("File location was updated");
        if (this.payPeriod !== this.responseClone.payPeriod) messages.push(`Pay period was updated to ${this.payPeriod}`);
        if (this.assignUser?.id !== this.responseClone.assignBy?.id) messages.push(`Assigner was changed to ${this.assignUser?.name}`);
        if (this.followUpUser?.id !== this.responseClone.followUpBy?.id) messages.push(`Follow up was changed to ${this.followUpUser?.name}`);
        if (this.deliveryUser?.id !== this.responseClone.deliverBy?.id) messages.push(`Delivery user was changed to ${this.deliveryUser?.name}`);
        if (this.isChecked !== this.responseClone.isChecked) messages.push(`Task was ${this.isChecked ? "checked" : "unchecked"}`);
        if (this.isBilled !== this.responseClone.isBilled) messages.push(`Task was ${this.isBilled ? "billed" : "unbilled"}`);
        return messages;
      }
    },
    closeForm() {
      if (this.checkFieldsAreEmpty && !this.gIsEditing) {
        this.confirmationModal = true;
      } else if (this.gIsEditing && this.checkIfFieldUpdated) {
        this.confirmationModal = true;
      } else {
        this.aSetIsEditing(false);
        this.$emit("close-task-form");
      }
    },
    discardTask() {
      this.confirmationModal = false;
      this.$emit("close-task-form");
    },
    openSlackForm(item) {
      if (this.gIsEditing) {
        this.inquiryTask = { ...item };
        this.slackFormModal = true;
      }
    },
    formatDateFromDB(date) {
      const [year, month, day] = date;
      const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      return formattedDate;
    },
    formatTimeFromDB(time) {
      const [hours, minutes] = time;
      const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
      return formattedTime;
    },
    formatUserObject(el) {
      if (!el) return {
        name: null,
        initials: null,
        id: null
      };
      return {
        name: `${el.user.firstName} ${el.user.lastName}`,
        initials: `${el.user.firstName[0]}${el.user.lastName[0]}`,
        id: el.user.id,
      }
    },
    openInNewTab() {
      const routeData = this.$router.resolve({ name: 'Assignment Dashboard' });
      window.open(routeData.href, '_blank');
    },
    formatDateForTitle(dateArray) {
      const [year, month, day] = dateArray;
      const date = new Date(year, month - 1, day);
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    },
    async publishTask() {
      this.publishIntent = true;
      this.createTaskLoader = true;
      try {
        if (this.checkRequiredFields) {
          const newTask = {
            id: this.gIsEditing && this.responseClone?.id ? this.responseClone?.id : null,
            taskId: this.idNumber,
            taskName: this.taskName,
            taskStatus: this.status,
            projectId: this.relatedProject.id,
            clientRequesterId: this.clientRequester.user.id,
            fileLocation: this.fileLocation,
            requestDate: this.taskRequestDate,
            payPeriod: this.payPeriod,
            deliveryDate: this.taskDeliveryDate,
            priority: this.taskPriority != "Empty" ? this.taskPriority : null,
            initiatedById: this.initiateUser ? this.initiateUser.id : null,
            assignById: this.assignUser.id,
            followUpById: this.followUpUser ? this.followUpUser.id : null,
            deliverById: this.deliveryUser ? this.deliveryUser.id : null,
            isBilled: this.isBilled,
            isChecked: this.isChecked,
            deliveryTime: this.taskDeliveryTime.length < 6 ? this.taskDeliveryTime + ':00' : this.taskDeliveryTime,
            requestTime: this.taskRequestTime.length < 6 ? this.taskRequestTime + ':00' : this.taskRequestTime,
          }
          const response = await newProcessTask(newTask);
          if (response.status >= 200 && response.status < 300) {
            if (this.gIsEditing) {
              const message = this.taskUpdateMessageGenerator();
              await processTaskComment({
                taskId: response.data.id,
                comment: message.length ? message.join('. ') : 'Task was updated',
                commentById: null,
                commentType: "ACTION"
              })
              this.showAlert({status: true, type: 'success', message: 'Task was updated successfully'});
            } else {
              await processTaskComment({
                taskId: response.data.id,
                comment: 'Created this task',
                commentById: null,
                commentType: "ACTION"
              })
              this.showAlert({status: true, type: 'success', message: 'Task was published successfully'});
            }
            const resp = await this.prepareFormData(response.data.id);
            let query = { ...this.$route.query };
            query = { ...query, openTaskForm: response.data.id };
            this.$router
              .push({ path: `/${ROUTES.pmDashboard}`, query })
              .catch(() => {});
            if (!this.gIsEditing) {
              this.itemToEditNew = resp;
            }
            this.responseClone = null;
            this.responseClone = _.cloneDeep(resp);
            this.formActivityUpdate++;
            this.aSetIsEditing(true);
            this.publishIntent = false;
          } else {
            this.showAlert({status: true, type: 'error', message: response.message});
          }
        }
        this.createTaskLoader = false;
      } catch (error) {
        console.log(error);
        this.showAlert({status: true, type: 'error'});
        this.createTaskLoader = false;
        this.publishIntent = false;
      }
    },
    onRelatedProjectFocus(e) {
      this.$refs.relatedProjectAutocomplete.isMenuActive = true;
    },
    onCloientRequesterFocus(e) {
      this.$refs.clientRequesterAutocomplete.isMenuActive = true;
    },
    onFileLocationFocus(e) {
      this.$refs.fileLocationAutocomplete.isMenuActive = true;
    },
    onAssignerFocus(e) {
      this.$refs.autocomplete.isMenuActive = true;
    },
    onFollowUpFocus(e) {
      this.$refs.followUpAutocomplete.isMenuActive = true;
    },
    onDeliveryFocus(e) {
      this.$refs.deliveryAutocomplete.isMenuActive = true;
    },
    async prepareFormData(id) {
      const response = await newGetTaskById(id);
      if (response.status === 200) {
        this.taskName = response.data.taskName;
        this.idNumber = response.data.taskId;
        this.status = response.data.taskStatus;
        const project = this.projectsList.find((el) => el.id === response.data.projectId);
        this.relatedProject = project;
        this.payPeriod = response.data.payPeriod;
        this.clientRequester = project?.clientRequesters.find((el) => el.user.id === response.data.clientRequesterId);
        this.taskDeliveryDate = response.data.deliveryDate ? this.formatDateFromDB(response.data.deliveryDate) : null;
        this.taskDeliveryTime = response.data.deliveryTime ? this.formatTimeFromDB(response.data.deliveryTime) : null;
        this.taskRequestDate = response.data.requestDate ? this.formatDateFromDB(response.data.requestDate) : null;
        this.taskRequestTime = response.data.requestTime ? this.formatTimeFromDB(response.data.requestTime) : null;
        this.fileLocation = response.data.fileLocation;
        this.isBilled = response.data.isBilled;
        this.isChecked = response.data.isChecked;
        this.taskPriority = response.data.priority ? response.data.priority : "Empty";
        this.initiateUser = response.data.initiatedBy
          ? this.formatUserObject(this.staffList.find((el) => el.user.id === response.data.initiatedBy.id))
          : null; 
        this.initiateStatus = response.data.initiateStatus;
        this.assignUser = response.data.assignBy
          ? this.formatUserObject(this.staffList.find((el) => el.user.id === response.data.assignBy.id))
          : null;
        this.assignStatus = response.data.assignStatus;
        this.followUpUser = response.data.followUpBy
          ? this.formatUserObject(this.staffList.find((el) => el.user.id === response.data.followUpBy.id))
          : null;
        this.followUpStatus = response.data.followUpStatus;
        this.deliveryUser = response.data.deliverBy
          ? this.formatUserObject(this.staffList.find((el) => el.user.id === response.data.deliverBy.id))
          : null;
        this.deliveryStatus = response.data.deliverStatus;
        this.currentDRI = response.data.currentDri
          ? this.formatUserObject(this.staffList.find((el) => el.user.id === response.data.currentDri.id))
          : null;
        this.assignmentsList = response.data.assignments;
        this.createdDate = response.data.createdDate;
        this.updatedDate = response.data.updatedDate;
        return response.data;
      }
    },
    handleClickOutside(event) {
      if (event.target.id != "clientTimePicker") {
        this.dropdownClientTimeOpen = false;
      }
      if (event.target.id != "myTimePicker") {
        this.dropdownMyTimeOpen = false;
      }
      if (event.target.id != "editableInput-assignUser") {
        if (this.assignerMenu && !this.assignUser && this.temporaryUserStorage) {
          this.assignerMenu = false;
          this.assignUser = _.cloneDeep(this.temporaryUserStorage);
          this.temporaryUserStorage = null;
        }
        else if (this.assignerMenu && !this.assignUser && !this.temporaryUserStorage) {
          this.assignerMenu = false;
        }
      }
      if (event.target.id != "editableInput-followUpUser") {
        if (this.followUpMenu && !this.followUpUser && this.temporaryUserStorage) {
          this.followUpMenu = false;
          this.followUpUser = _.cloneDeep(this.temporaryUserStorage);
          this.temporaryUserStorage = null;
        } else if (this.followUpMenu && !this.followUpUser && !this.temporaryUserStorage) {
          this.followUpMenu = false;
        }
      }
      if (event.target.id != "editableInput-deliveryUser") {
        if (this.deliveryUserMenu && !this.deliveryUser && this.temporaryUserStorage) {
          this.deliveryUserMenu = false;
          this.deliveryUser = _.cloneDeep(this.temporaryUserStorage);
          this.temporaryUserStorage = null;
        } else if (this.deliveryUserMenu && !this.deliveryUser && !this.temporaryUserStorage) {
          this.deliveryUserMenu = false;
        }
      }
      if (event.target.id != "editableInput-clientRequester") {
        if (this.clientRequesterMenu && this.clientRequester.user.firstName === "Empty" && this.temporaryUserStorage) {
          this.clientRequesterMenu = false;
          this.clientRequester = _.cloneDeep(this.temporaryUserStorage);
          this.temporaryUserStorage = null;
        } else if (this.clientRequesterMenu && this.clientRequester.user.firstName === "Empty" && !this.temporaryUserStorage) {
          this.clientRequesterMenu = false;
        }
      }
      if (event.target.id != "editableInput-relatedProject") {
        if (this.relatedProjectMenu && this.relatedProject.name === "Empty" && this.temporaryUserStorage) {
          this.relatedProjectMenu = false;
          this.relatedProject = _.cloneDeep(this.temporaryUserStorage);
          this.temporaryUserStorage = null;
        } else if (this.relatedProjectMenu && this.relatedProject.name === "Empty" && !this.temporaryUserStorage) {
          this.relatedProjectMenu = false;
        }
      }
      if (event.target.id != "editableInput-fileLocation") {
        if (this.fileLocationMenu && !this.fileLocation && this.temporaryUserStorage) {
          this.fileLocationMenu = false;
          this.fileLocation = _.cloneDeep(this.temporaryUserStorage);
          this.temporaryUserStorage = null;
        } else if (this.fileLocationMenu && !this.fileLocation && !this.temporaryUserStorage) {
          this.fileLocationMenu = false;
        }
      }
    },
    updateHeight() {
      this.windowWidth = window.innerWidth;
      this.$forceUpdate();
    },
  },
  async mounted() {
    let relatedProjectInput = this.$refs.relatedProjectAutocomplete.$refs.input;
    relatedProjectInput.addEventListener('focus', this.onRelatedProjectFocus, true);
    let clientRequesterInput = this.$refs.clientRequesterAutocomplete.$refs.input;
    clientRequesterInput.addEventListener('focus', this.onCloientRequesterFocus, true);
    let fileLocationInput = this.$refs.fileLocationAutocomplete.$refs.input;
    fileLocationInput.addEventListener('focus', this.onFileLocationFocus, true);
    let assignerAutocompleteInput = this.$refs.autocomplete.$refs.input;
    assignerAutocompleteInput.addEventListener('focus', this.onAssignerFocus, true);
    let followUpAutocompleteInput = this.$refs.followUpAutocomplete.$refs.input;
    followUpAutocompleteInput.addEventListener('focus', this.onFollowUpFocus, true);
    let deliveryAutocompleteInput = this.$refs.deliveryAutocomplete.$refs.input;
    deliveryAutocompleteInput.addEventListener('focus', this.onDeliveryFocus, true);
    document.addEventListener('click', this.handleClickOutside);
    window.addEventListener('resize', this.updateHeight);
    let myTimeFromLocaleStorage = localStorage.getItem("myTimeTaskForm");
    if (myTimeFromLocaleStorage) {
      this.myTime = JSON.parse(myTimeFromLocaleStorage);
    }
    if (!this.gIsEditing) {
      this.initiateUser = {
        name: this.user.user.firstName + " " + this.user.user.lastName,
        id: this.user.user.id,
        initials: this.user.user.firstName[0] + this.user.user.lastName[0],
      }
    }
    const today = new Date();
    
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    this.taskRequestDate = `${year}-${month}-${day}`;
    
    const hours = String(today.getHours()).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    this.taskRequestTime = `${hours}:${minutes}`;
    if (this.itemToEdit.id) {
      const response = await this.prepareFormData(this.itemToEdit.id);
      if (response) {
        this.responseClone = _.cloneDeep(response);
      }
    }
  },
   beforeDestroy() {
    let relatedProjectInput = this.$refs.relatedProjectAutocomplete.$refs.input;
    relatedProjectInput.removeEventListener('focus', this.onRelatedProjectFocus, true);
    let clientRequesterInput = this.$refs.clientRequesterAutocomplete.$refs.input;
    clientRequesterInput.removeEventListener('focus', this.onCloientRequesterFocus, true);
    let fileLocationInput = this.$refs.fileLocationAutocomplete.$refs.input;
    fileLocationInput.removeEventListener('focus', this.onFileLocationFocus, true);
    let assignerAutocompleteInput = this.$refs.autocomplete.$refs.input;
    assignerAutocompleteInput.removeEventListener('focus', this.onAssignerFocus, true);
    let followUpAutocompleteInput = this.$refs.followUpAutocomplete.$refs.input;
    followUpAutocompleteInput.removeEventListener('focus', this.onFollowUpFocus, true);
    let deliveryAutocompleteInput = this.$refs.deliveryAutocomplete.$refs.input;
    deliveryAutocompleteInput.removeEventListener('focus', this.onDeliveryFocus, true);
    document.removeEventListener('click', this.handleClickOutside);
    window.removeEventListener('resize', this.updateHeight);
  },
};
</script>

<style lang="scss" scoped>
.pm-form {
  border-radius: 15px !important;
  position: relative;
  &__messages {
    position: absolute;
    background-color: white;
    z-index: 9999;
    bottom: 0;
    border-radius: 15px !important;
    left: 0;
    width: 100%;
    & .v-alert {
      margin-bottom: 0px !important;
      border-radius: 15px !important;
    }
  }
}

.pmdashboard {
  font-family: 'Roboto', sans-serif !important;
  &__title {
    font-family: 'Roboto', sans-serif !important;
    border-bottom: 1px solid #e0e0e0;
    p {
      margin: 0;
    }
    &--left {
      font-size: 16px !important;
      font-weight: 600;
      color: #656F7D !important;
    }
    &--right {
      min-width: 40%;
      justify-content: flex-end;
      &--text {
        font-size: 14px !important;
        font-weight: 300;
        color: #656F7D;
      }
      &--publish {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        background-color: #1976D2;
        border-radius: 5px;
        color: #FFFFFF;
        font-weight: 400;
        font-size: 16px;
        font-family: 'Roboto', sans-serif !important;
        line-height: 18px;
        opacity: 0.9;
        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
      &--delimetr {
        width: 1px;
        height: 20px;
        background-color: #e0e0e0;
      }
      &--icon {
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &--close {
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  &__task {
    &--title {
      padding: 25px 35px;
      & .task {
        &--id {
          font-size: 16px !important;
          font-weight: 600;
          color: #656F7D !important;
          margin-bottom: 0px;
        }
        &--title {
          font-size: 26px;
          font-weight: 600;
          margin-bottom: 4px;
        }
        &--dri {
          display: flex;
          align-items: center;
          &--text {
            font-size: 16px !important;
            font-weight: 600 !important;
            font-family: 'Roboto', sans-serif !important;
            color: #656F7D;
            margin-bottom: 0px;
          }
          &--circle {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #7C99E2;
            margin-left: 6px;
            margin-right: 6px;
          }
          &--name {
            font-size: 16px !important;
            font-weight: 400 !important;
            color: #000000;
            font-family: 'Roboto', sans-serif !important;
            margin-bottom: 0px;
            &-dash {
              margin: 0;
              margin-left: 6px;
              font-family: Roboto;
              font-size: 16px;
              font-weight: 600;
              color: #B4B4B4 !important;
            }
          }
        }
      }
    }
    &--delimetr-horizontal {
      height: 1px;
      width: calc(100% - 20px);
      background-color: #e0e0e0;
    }
    &--content {
      padding: 0 35px;
      &--assignments {
        &-text {
          font-family: 'Roboto', sans-serif !important;
          font-size: 16px !important;
          font-weight: 400 !important;
          line-height: 18px !important;
          text-align: left !important;
          color: #B4B4B4 !important;
        }
      }
      
      &-left {
        &--text {
          font-size: 16px;
          color: #656F7D;
          margin-bottom: 0px;
          width: 100% !important;
          & .user-status-name {
            display: flex;
            align-items: center;
            &-initials {
              min-width: 36px;
              height: 36px;
              font-size: 16px;
              font-weight: 400;
              color: #fff;
              border-radius: 50%;
              background-color: #9747FF;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            &-text {
              color: #000;
              font-size: 16px !important;
              font-weight: 400 !important;
              font-family: 'Roboto', sans-serif !important;
              & a {
                color: #0000EE !important;
              }
            }
          }
          & .user-status-pending {
            &-container {
              border: 2px dashed #656F7D;
              color: #656F7D !important;
              padding: 8px 5px;
              width: 80px;
              height: 32px;
              border-radius: 15px;
              font-size: 16px !important;
              font-weight: 400 !important;
              font-family: Roboto !important;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        &--chips-item {
          font-family: Roboto, sans-serif !important;
          font-size: 16px !important;
          font-weight: 500 !important;
          line-height: 18px !important;
          text-align: left !important;
          border-radius: 5px !important;
          &:hover {
            cursor: pointer;
          }
        }
        &--chip {
          color: #fff !important;
          height: 27px !important;
          margin-left: 8px !important;
        }
        &--autocomplete {
          max-width: 133px;
          font-size: 14px !important;
          & .v-input__control {
            & .v-input__slot {
              max-height: 23px !important;
              padding: 0 !important;
            }
          }
        }
        &--dropdown {
          display: inline-block;
          position: relative;
          width: 180px;
          border-radius: 5px;
          .dropdown-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 5px;
            padding: 4px;
            cursor: pointer;
          }
          .dropdown-text {
            flex-grow: 1;
            font-size: 16px;
            font-weight: 400;
            color: #656F7D !important;
            font-family: 'Roboto', sans-serif !important;
          }
          .dropdown-arrow {
            background-color: transparent;
            border: none;
            cursor: pointer;
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 12px 12px;
            width: 20px;
            height: 20px;
          }
          .dropdown-menu {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin: 0;
            padding: 0;
            list-style: none;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 4px;
            max-height: 200px;
            min-width: 200px;
            overflow-y: auto;
            z-index: 1000;
          }
          .dropdown-menu li {
            padding: 10px;
            cursor: pointer;
          }
          .dropdown-menu li:hover {
            background-color: #f0f0f0;
          }
          .background-color {
            background-color: #E8E8E8 !important;
          }
        }
      }
    }
    &--settings {
      width: 60px !important;
      min-height: calc(100% - 200px) !important;
      padding: 10px 8px 0 8px !important;
      border-left: 1px solid #E8EAED !important;
      &--button {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 44px;
        height: 44px;
        &:hover {
          cursor: pointer;
        }
        &-icon {
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          padding: 2px;
        }
        & .settings--text {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          color: #757575;
        }
      }
    }
  }
  &__confirmation-modal {
    background-color: #fff !important;
    width: 500px !important;
    display: flex;
    flex-direction: column;
    padding: 30px;
    &--title {
      color: #000000 !important;
      font-weight: 500 !important;
      font-size: 20px !important;
    }
    &--text {
      color: #000000 !important;
      font-weight: 300 !important;
      font-size: 14px !important;
    }
    &--buttons {
      margin-top: 20px;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 700 !important;
      font-size: 14px !important;
      &-discard {
        color: #fff !important;
      }
    }
  }
}

.row-styles {
  display: flex !important;
  flex-direction: row !important;
}

.ml--3 {
  margin-left: -24px;
}

.w-90 {
  width: 90% !important;
}

.bradius-4 {
  border-radius: 4px !important;
}

.hover {
  background-color: #F2F2F2;
}

.error-color {
  color: #BE2629 !important;
}

.chip-width {
  width: 80px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.file-location-input {
  height: 40px !important;
}

.dropdown-initials-chip {
  border-radius: 50% !important;
  height: 40px !important;
}

.pmdashboard__clear {
  width:16px;
  height: 16px;
  padding: 4px;
  border-radius: 2px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 9000 !important;
  position: absolute !important;
  &--title {
    top: 50%;
    transform: translateY(-45%);
    right: 15px;
  }
  &:hover {
    background-color: #DEDEDE;
    cursor: pointer;
  }
  &--employer {
    top: 50%;
    transform: translateY(-45%);
    right: 6px !important;
  }
}

.empty-p-field {
  color: #B4B4B4 !important;
  padding: 2px 5px;
  height: 40px;
  display: flex;
  align-items: center;
  &-taskName {
    padding: 2px 0;
    color: #B4B4B4 !important;
    height: 43px !important;
  }
  &-idNumber {
    color: #656F7D !important;
    font-size: 16px;
    font-weight: 600;
  }
}

.fullfield-p-field {
  color: #000000 !important;
  padding: 2px 5px;
  font-weight: 400;
  height: 40px;
  display: flex;
  align-items: center;
  &-taskName {
    padding: 2px 0;
    height: 43px !important;
  }
  &-idNumber {
    color: #656F7D !important;
    font-size: 16px;
    font-weight: 600;
  }
  &-requestDate {
    color: #000000 !important;
    font-size: 16px;
    padding: 2px 5px;
    height: 40px;
    display: flex;
    align-items: center;
  }
  &-deliveryDate {
    color: #000000 !important;
    font-size: 16px;
    padding: 2px 5px;
    height: 40px;
    display: flex;
    align-items: center;
  }
  &-taskStatus {
    height: 40px !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: start !important;
  }
}

.input-field {
  border: none;
  outline: none;
  width: 100% !important;
  background-color: #F2F2F2;
  padding: 2px 5px;
  &-taskName {
    padding: 2px 0;
    font-size: 26px;
    font-weight: 600;
  }
  &-idNumber {
    font-size: 16px !important;
    font-weight: 600 !important;
    padding: 0;
    color: #656F7D !important;
  }
}

.assignments-title {
  font-size: 18px;
  font-weight: 600;
  color: #656F7D;
  margin-bottom: 0px;
}

.input-fileLocation {
  color: #2600ff !important;
  text-decoration: underline !important;
}

.w-100 {
  width: 100% !important;
}

.additional-hover {
  &:hover {
    cursor: pointer;
  }
}

.p-relative {
  position: relative !important;
}

.custom-icon::before {
  content: "\F12F0";
  display: inline-block;
  width: 12px;
  height: 8px !important;
  font-family: "Material Design Icons";
  font-size: 18px;
  margin-bottom: 14px;
}

.fixed-width-col {
  width: 160px !important;
  max-width: 160px !important;
  min-width: 160px !important;
}

.col-7 {
  max-width: 100% !important;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
}

.row-min-height {
  min-height: 35.9px !important;
}

.custom-chip {
  background-color: white !important;
  border: 3px dashed #656F7D !important;
  border-radius: 15px !important;
  font-size: 14px !important;
  padding: 0 8px !important;
}

.scrollable-col {
  height: 330px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 90px 0px 90px !important;
  width: 100%;
  position: relative !important;
}

/* WebKit browsers */
.scrollable-col::-webkit-scrollbar {
  width: 10px;
}

.scrollable-col::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable-col::-webkit-scrollbar-thumb {
  background: #ADB3BD;
  border-radius: 8px;
}

.scrollable-col::-webkit-scrollbar-thumb:hover {
  background: #ADB3BD;
}

/* Firefox */
.scrollable-col {
  scrollbar-width: thin;
  scrollbar-color: #ADB3BD #f1f1f1;
}

@keyframes shake {
  0%, 100% { transform: translateY(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateY(-7px); }
  20%, 40%, 60%, 80% { transform: translateY(7px); }
}

.shake {
  animation: shake 1s ease-in-out;
}

.animated-section {
  animation: fadeIn 0.9s ease forwards;
  opacity: 1;
  transition: opacity 0.9s ease, transform 0.9s ease;
}

[v-show="false"] {
  animation: fadeOut 0.9s ease forwards;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px); /* optional slide-in effect */
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px); /* reverse slide-out effect */
  }
}

@media screen and (max-width: 1420px) {
  .scrollable-col {
    padding: 0 20px 0px 20px !important;
  }
}

@media screen and (max-width: 1285px) {
  .scrollable-col {
    padding: 0 50px 0px 50px !important;
  }
}

@media screen and (max-width: 992px) {
  .scrollable-col {
    padding: 0 20px 0px 20px !important;
  }
}
</style>
